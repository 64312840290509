// @flow

import { ReferencesTransform } from '@performant-software/controlled-vocabulary';
import { BaseTransform } from '@performant-software/shared-components';
import type { Person as PersonType } from '@udcsl/shared';
import Participations from './Participantions';

/**
 * Class responsible for transforming people objects.
 */
class Person extends BaseTransform {
  /**
   * Returns the array of people payload keys.
   *
   * @returns {*[]}
   */
  getPayloadKeys(): Array<string> {
    return [
      'first_name',
      'last_name',
      'name',
      'biography'
    ];
  }

  /**
   * Returns the organization parameter name.
   *
   * @returns {string}
   */
  getParameterName(): string {
    return 'person';
  }

  /**
   * Returns the passed person as a dropdown option.
   *
   * @param person
   *
   * @returns {{text, value, key}}
   */
  toDropdown(person: PersonType): any {
    return {
      key: person.id,
      value: person.id,
      text: person.name
    };
  }

  /**
   * Returns the passed person as a payload for POST/PUT requests.
   *
   * @param person
   *
   * @returns {{[p: string]: {[p: string]: *}}}
   */
  toPayload(person: PersonType): any {
    return super.toPayload(person, {
      ...ReferencesTransform.toPayload(person, 'profession'),
      ...Participations.toPayload(person, 'organization_participations')
    });
  }
}

const PersonTransform: Person = new Person();
export default PersonTransform;
