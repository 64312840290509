// @flow

import { BaseService, BaseTransform } from '@performant-software/shared-components';
import type { UserData } from '@udcsl/shared';
import jwtDecode from 'jwt-decode';
import AuthenticationTransform from '../transforms/Authentication';

const STORAGE_KEY = 'udcsl_user_token';

class Authentication extends BaseService {
  /**
   * Returns the authentication base URL.
   *
   * @returns {string}
   */
  getBaseUrl(): string {
    return '/api/auth/login';
  }

  /**
   * Returns the token from local storage.
   *
   * @returns {?string}
   */
  getToken(): ?string {
    return localStorage.getItem(STORAGE_KEY);
  }

  /**
   * Returns the user data for the currently stored token.
   *
   * @returns {UserData}
   */
  getUser(): UserData {
    const token = this.getToken();
    return jwtDecode(token);
  }

  /**
   * Returns the authentication transform object.
   *
   * @returns {Authentication}
   */
  getTransform(): typeof BaseTransform {
    return AuthenticationTransform;
  }

  /**
   * Returns true if the current user is authenticated.
   *
   * @returns {*|boolean}
   */
  isAuthenticated(): boolean {
    const token = this.getToken();

    if (!(token && token.length)) {
      return false;
    }

    const { exp } = jwtDecode(token);
    const expirationDate = new Date(exp * 1000);
    const today = new Date();

    return expirationDate.getTime() > today.getTime();
  }

  /**
   * Attempts to authenticate the current user and stores the response in local storage.
   *
   * @param params
   *
   * @returns {*}
   */
  login(params: any): Promise<any> {
    return this
      .create(params)
      .then(({ data: { token } }) => this.loginToken(token));
  }

  /**
   * Logs the user in via token. This method is useful for single sign on implementations.
   *
   * @param token
   *
   * @returns {Promise<*>}
   */
  loginToken(token: string): Promise<any> {
    return new Promise((resolve, reject) => {
      // Decode the token
      const decoded = jwtDecode(token);

      // Login fails if the user does not have admin access
      if (!decoded.admin_access) {
        reject();
        return;
      }

      // Otherwise, log the user in
      this.setToken(token).then(resolve);
    });
  }

  /**
   * Removes the properties of the current user from local storage.
   *
   * @returns {Promise<*>}
   */
  logout(): Promise<any> {
    localStorage.removeItem(STORAGE_KEY);
    return Promise.resolve();
  }

  /**
   * Sets the token in local storage.
   *
   * @param token
   */
  setToken(token: string): Promise<any> {
    localStorage.setItem(STORAGE_KEY, token);
    return Promise.resolve();
  }
}

const AuthenticationService: Authentication = new Authentication();
export default AuthenticationService;
