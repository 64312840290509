// @flow

import { FileUploadModal, ItemList, LazyMedia } from '@performant-software/semantic-components';
import React, { type ComponentType, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useLocation, useNavigate, useParams } from 'react-router-dom';
import { Label } from 'semantic-ui-react';
import FileUpload from '../components/FileUpload';
import FileUploadHeader from '../components/FileUploadHeader';
import MediaContentsService from '../services/MediaContents';
import { Visibility } from '../constants/Visibility';
import VisibilityLabel from '../components/VisibilityLabel';

const MediaContents: ComponentType<any> = () => {
  const [modal, setModal] = useState(false);
  const location = useLocation();
  const navigate = useNavigate();
  const { projectId } = useParams();
  const { t } = useTranslation();

  return (
    <>
      <ItemList
        actions={[{
          name: 'edit',
          onClick: (item) => navigate(`${item.id}`)
        }, {
          name: 'delete'
        }]}
        addButton={{
          location: 'top',
          onClick: () => navigate('new')
        }}
        buttons={[{
          content: t('Common.buttons.upload'),
          icon: 'cloud upload',
          primary: true,
          onClick: () => setModal(true)
        }]}
        collectionName='media_contents'
        onLoad={(params) => MediaContentsService.fetchAll({ ...params, project_id: projectId })}
        onDelete={(mediaContent) => MediaContentsService.delete(mediaContent)}
        renderDescription={(mediaContent) => mediaContent.description}
        renderExtra={(mediaContent) => (
          <Label.Group>
            { mediaContent.primary && (
              <Label
                color='green'
                content={t('MediaContents.labels.primary')}
                icon='checkmark'
              />
            )}
            <VisibilityLabel
              visibility={mediaContent.visibility}
            />
          </Label.Group>
        )}
        renderHeader={(mediaContent) => mediaContent.label}
        renderImage={(mediaContent) => (
          <LazyMedia
            dimmable={false}
            contentType={mediaContent.content_type}
            preview={mediaContent.content_thumbnail_url}
          />
        )}
        renderMeta={(mediaContent) => mediaContent.caption}
        saved={location.state && location.state.saved}
        session={{
          key: 'MediaContentsList',
          storage: sessionStorage
        }}
        sort={[{
          key: 'label',
          value: 'media_contents.label',
          text: t('Common.sort.label')
        }, {
          key: 'created_at',
          value: 'media_contents.created_at',
          text: t('Common.sort.created')
        }, {
          key: 'updated_at',
          value: 'media_contents.updated_at',
          text: t('Common.sort.updated')
        }]}
      />
      { modal && (
        <FileUploadModal
          closeOnComplete={false}
          button={t('Common.buttons.upload')}
          headerComponent={FileUploadHeader}
          itemComponent={FileUpload}
          onAddFile={(file) => ({
            label: file.name,
            visibility: Visibility.private,
            project_id: projectId,
            content: file,
            content_url: URL.createObjectURL(file),
            content_type: file.type
          })}
          onClose={() => {
            setModal(false);
            navigate(location.pathname, { state: { saved: true } });
          }}
          onSave={(media) => MediaContentsService.save(media)}
          required={{
            label: t('FileUpload.labels.label'),
            copyright: t('FileUpload.labels.copyright'),
            file_type: t('FileUpload.labels.fileType'),
            visibility: t('FileUpload.labels.visibility')
          }}
          showPageLoader={false}
          strategy='single'
        />
      )}
    </>
  );
};

export default MediaContents;
