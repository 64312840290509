// @flow

import React, { useEffect, useState, type ComponentType } from 'react';
import { useTranslation } from 'react-i18next';
import { Link, useParams } from 'react-router-dom';
import { Breadcrumb, Loader } from 'semantic-ui-react';
import BreadcrumbsService from '../services/Breadcrumbs';

type Props = {
  active: boolean,
  id?: number,
  name: string,
  url: string
};

const BreadcrumbItem: ComponentType<any> = (props: Props) => {
  const [loading, setLoading] = useState(false);
  const [name, setName] = useState();

  const params = useParams();
  const { t } = useTranslation();

  /**
   * Sets or clears the name attribute on the state.
   */
  useEffect(() => {
    if (props.id?.toString() === 'new') {
      setName(t('BreadcrumbItem.labels.new'));
    } else if (props.id) {
      BreadcrumbsService
        .onLoad(props.name, props.id, params)
        .then((n) => setName(n))
        .finally(() => setLoading(false));

      setLoading(true);
    } else {
      setName(null);
    }
  }, [props.id, props.name]);

  return (
    <>
      <Breadcrumb.Section
        active={props.active && !props.id}
        as={Link}
        to={`/${props.url}`}
      >
        { t(`BreadcrumbItem.labels.${props.name}`) }
      </Breadcrumb.Section>
      { props.id && (
        <Breadcrumb.Divider
          icon='right chevron'
        />
      )}
      { loading && (
        <Loader
          active
          inline
        />
      )}
      { name && props.id && (
        <Breadcrumb.Section
          active={props.active}
          as={Link}
          to={`/${props.url}/${props.id}`}
        >
          { name }
        </Breadcrumb.Section>
      )}
    </>
  );
};

export default BreadcrumbItem;
