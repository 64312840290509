// @flow

import { ReferenceCodeFormDropdown } from '@performant-software/controlled-vocabulary';
import { AssociatedDropdown } from '@performant-software/semantic-components';
import type { EditContainerProps } from '@performant-software/shared-components/types';
import React, { type ComponentType, type Element, useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import { Form, Modal } from 'semantic-ui-react';

type Props = EditContainerProps & {
  attribute: string,
  collectionName: string,
  label: string,
  modal: {
    component: ComponentType<any>,
    onSave: (item: any) => Promise<any>
  },
  onSearch: (search: string) => Promise<any>,
  renderItem: (item: any) => string,
  renderOption: (item: any) => Element<any>,
  rolesReferenceTable: string,
  title: {
    add: string,
    edit: string
  }
};

const ParticipationModal: ComponentType<any> = (props: Props) => {
  const { t } = useTranslation();

  /**
   * Resolves the object key based on the attribute prop.
   *
   * @type {string}
   */
  const object = useMemo(() => props.attribute.slice(0, props.attribute.indexOf('_')), [props.attribute]);

  return (
    <Modal
      as={Form}
      centered={false}
      open
    >
      <Modal.Header
        content={props.item.id ? props.title.add : props.title.edit}
      />
      <Modal.Content>
        <Form.Input
          error={props.isError(props.attribute)}
          label={props.label}
          required={props.isRequired(props.attribute)}
        >
          <AssociatedDropdown
            collectionName={props.collectionName}
            modal={props.modal}
            onSearch={props.onSearch}
            onSelection={props.onAssociationInputChange.bind(this, props.attribute, object)}
            renderOption={props.renderOption}
            searchQuery={props.item[object] && props.renderItem(props.item[object])}
            value={props.item[props.attribute]}
          />
        </Form.Input>
        <ReferenceCodeFormDropdown
          error={props.isError('roles')}
          label={t('ParticipationModal.labels.roles')}
          multiple
          required={props.isRequired('roles')}
          onChange={(roles) => props.onSetState({ roles })}
          referenceTable={props.rolesReferenceTable}
          value={props.item.roles}
        />
        <Form.TextArea
          error={props.isError('description')}
          label={t('ParticipationModal.labels.description')}
          required={props.isRequired('description')}
          onChange={props.onTextInputChange.bind(this, 'description')}
          value={props.item.description || ''}
        />
      </Modal.Content>
      { props.children }
    </Modal>
  );
};

export default ParticipationModal;
