// @flow

import React, {
  useState,
  useMemo,
  useCallback,
  type ComponentType
} from 'react';
import _ from 'underscore';
import { Button, Dropdown, Input } from 'semantic-ui-react';
import { VisibilityOptions } from '../constants/Visibility';
import { ReferenceCodeDropdown } from '@performant-software/controlled-vocabulary';
import styles from './FileUploadHeader.module.css';
import { useTranslation } from 'react-i18next';

type Props = {
  items: Array<any>,
  onSetItems: (items: Array<any>) => void,
  uploading: boolean
};

const FileUploadHeader: ComponentType<any> = (props: Props) => {
  const [copyright, setCopyright] = useState();
  const [visibility, setVisibility] = useState();
  const [fileType, setFileType] = useState();

  const { t } = useTranslation();

  /**
   * Sets the disabled indicator for the "Apply to all" button if the files are uploading or the fields are empty.
   *
   * @type {unknown}
   */
  const disabled = useMemo(() => (
    props.uploading || (_.isEmpty(copyright) && _.isEmpty(fileType) && _.isEmpty(visibility))
  ), [copyright, fileType, visibility, props.uploading]);

  /**
   * Sets the icon variable.
   *
   * @type {{onClick: function(): void, name: string, link: boolean}}
   */
  const icon = useMemo(() => ({
    name: 'times',
    link: true,
    onClick: () => setCopyright('')
  }), []);

  /**
   * Applies the copyright, file type, and visibility to all items in the list.
   *
   * @type {(function(): void)|*}
   */
  const onApplyToAll = useCallback(() => {
    props.onSetItems(_.map(props.items, (i) => ({
      ...i,
      ...(!!copyright && { copyright }),
      ...(fileType && { file_type: fileType }),
      ...(visibility && { visibility }),
    })));
  }, [props.items, copyright, fileType, visibility]);

  // Hide the component if no items are selected for upload
  if (_.isEmpty(props.items)) {
    return null;
  }

  return (
    <div
      className={styles.fileUploadHeader}
    >
      <ReferenceCodeDropdown
        disabled={props.uploading}
        fluid={false}
        onChange={(f) => setFileType(f)}
        placeholder={t('FileUploadHeader.labels.fileType')}
        referenceTable='file_type'
        value={fileType}
      />
      <Dropdown
        clearable
        disabled={props.uploading}
        onChange={(e, { value }) => setVisibility(value)}
        value={visibility}
        placeholder={t('FileUploadHeader.labels.visibility')}
        options={VisibilityOptions}
        selection
        selectOnBlur={false}
      />
      <Input
        className={styles.copyright}
        disabled={props.uploading}
        icon={_.isEmpty(copyright) ? null : icon}
        placeholder={t('FileUploadHeader.labels.copyright')}
        onChange={(e, { value }) => setCopyright(value)}
        value={copyright}
      />
      <Button
        color='red'
        content={t('FileUploadHeader.buttons.apply')}
        disabled={disabled}
        onClick={onApplyToAll}
      />
    </div>
  );
};

export default FileUploadHeader;
