// @flow

import cx from 'classnames';
import React, { type ComponentType } from 'react';
import { withTranslation } from 'react-i18next';
import { FaUsersCog } from 'react-icons/fa';
import { VscReferences } from 'react-icons/vsc';
import { Link } from 'react-router-dom';
import { Card, Header, Image } from 'semantic-ui-react';
import styles from './Settings.module.css';

const Settings: ComponentType<any> = withTranslation()((props) => (
  <div
    className={styles.settings}
  >
    <Header
      as='h1'
      className={cx(styles.ui, styles.header)}
      content={props.t('Settings.header')}
      subheader={props.t('Settings.subheader')}
    />
    <Card.Group
      className={cx(styles.ui, styles.cards)}
    >
      <Card
        as={Link}
        className={cx(styles.ui, styles.card)}
        link
        to='users'
      >
        <Image
          className={styles.image}
        >
          <FaUsersCog
            size='6em'
          />
        </Image>
        <Card.Content
          description={props.t('Settings.users.description')}
          header={props.t('Settings.users.header')}
          meta={props.t('Settings.users.meta')}
        />
      </Card>
      <Card
        as={Link}
        className={cx(styles.ui, styles.card)}
        link
        to='reference_tables'
      >
        <Image
          className={styles.image}
        >
          <VscReferences
            size='6em'
          />
        </Image>
        <Card.Content
          description={props.t('Settings.reference.description')}
          header={props.t('Settings.reference.header')}
          meta={props.t('Settings.reference.meta')}
        />
      </Card>
    </Card.Group>
  </div>
));

export default Settings;
