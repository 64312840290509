// @flow

import { BaseTransform } from '@performant-software/shared-components';
import type { ProjectAward as ProjectAwardType } from '@udcsl/shared';
import Participantions from './Participantions';

/**
 * Class responsible for transforming project award objects.
 */
class ProjectAward extends BaseTransform {
  /**
   * Returns the project award parameter name.
   *
   * @returns {string}
   */
  getParameterName(): string {
    return 'project_award';
  }

  /**
   * Returns the project award payload keys.
   *
   * @returns {string[]}
   */
  getPayloadKeys(): Array<string> {
    return [
      'project_id',
      'award_id',
      'date',
      'date_display',
      'description',
      'website_url'
    ];
  }

  /**
   * Returns the passed project award for PUT/POST requests.
   *
   * @param projectAward
   *
   * @returns {*}
   */
  toPayload(projectAward: ProjectAwardType): any {
    return super.toPayload(projectAward, {
      ...Participantions.toPayload(projectAward, 'person_participants')
    });
  }
}

const ProjectAwardTransform: ProjectAward = new ProjectAward();
export default ProjectAwardTransform;
