// @flow

import { ReferenceTablesList } from '@performant-software/controlled-vocabulary';
import { useDragDrop } from '@performant-software/shared-components';
import React, { type ComponentType } from 'react';
import { BrowserRouter as Router, Routes, Route } from 'react-router-dom';
import AuthenticatedRoute from './components/AuthenticatedRoute';
import Award from './pages/Award';
import Awards from './pages/Awards';
import Event from './pages/Event';
import Events from './pages/Events';
import Layout from './components/Layout';
import Login from './pages/Login';
import MediaContent from './pages/MediaContent';
import MediaContents from './pages/MediaContents';
import Organizations from './pages/Organizations';
import Organization from './pages/Organization';
import People from './pages/People';
import Person from './pages/Person';
import Project from './pages/Project';
import ProjectAward from './pages/ProjectAward';
import ProjectAwards from './pages/ProjectAwards';
import Projects from './pages/Projects';
import Settings from './pages/Settings';
import User from './pages/User';
import Users from './pages/Users';

const App: ComponentType<any> = useDragDrop(() => (
  <Router>
    <Routes>
      <Route
        path='/'
        element={<Login />}
        exact
      />
      <Route
        element={(
          <AuthenticatedRoute>
            <Layout />
          </AuthenticatedRoute>
        )}
        path='/'
      >
        <Route
          path='/awards'
        >
          <Route
            element={<Awards />}
            index
          />
          <Route
            element={<Award />}
            path='new'
          />
          <Route
            path=':awardId'
          >
            <Route
              element={<Award />}
              index
            />
            <Route
              path='project_awards'
            >
              <Route
                element={<ProjectAwards />}
                index
              />
              <Route
                element={<ProjectAward />}
                path='new'
              />
              <Route
                element={<ProjectAward />}
                path=':projectAwardId'
              />
            </Route>
          </Route>
        </Route>
        <Route
          path='/projects'
        >
          <Route
            element={<Projects />}
            index
          />
          <Route
            element={<Project />}
            path='new'
          />
          <Route
            path=':projectId'
          >
            <Route
              element={<Project />}
              index
            />
            <Route
              path='project_awards'
            >
              <Route
                element={<ProjectAwards />}
                index
              />
              <Route
                element={<ProjectAward />}
                path='new'
              />
              <Route
                element={<ProjectAward />}
                path=':projectAwardId'
              />
            </Route>
            <Route
              path='events'
            >
              <Route
                element={<Events />}
                index
              />
              <Route
                element={<Event />}
                path='new'
              />
              <Route
                element={<Event />}
                path=':eventId'
              />
            </Route>
            <Route
              path='media_contents'
            >
              <Route
                element={<MediaContents />}
                index
              />
              <Route
                element={<MediaContent />}
                path='new'
              />
              <Route
                element={<MediaContent />}
                path=':mediaContentId'
              />
            </Route>
          </Route>
        </Route>
        <Route
          path='organizations'
        >
          <Route
            element={<Organizations />}
            index
          />
          <Route
            element={<Organization />}
            path='new'
          />
          <Route
            element={<Organization />}
            path=':organizationId'
          />
        </Route>
        <Route
          path='people'
        >
          <Route
            element={<People />}
            index
          />
          <Route
            element={<Person />}
            path='new'
          />
          <Route
            element={<Person />}
            path=':personId'
          />
        </Route>
        <Route
          path='/settings'
        >
          <Route
            element={<Settings />}
            index
          />
          <Route
            path='users'
          >
            <Route
              element={<Users />}
              index
            />
            <Route
              element={<User />}
              path='new'
            />
            <Route
              element={<User />}
              path=':userId'
            />
          </Route>
          <Route
            element={<ReferenceTablesList />}
            path='reference_tables'
          />
        </Route>
      </Route>
    </Routes>
  </Router>
));

export default App;
