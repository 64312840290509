// @flow

import type { EditContainerProps } from '@performant-software/shared-components/types';
import React, { type ComponentType } from 'react';
import { useTranslation } from 'react-i18next';
import AwardForm from '../components/AwardForm';
import AwardsService from '../services/Awards';
import Names from '../utils/Names';
import SimpleEditPage from '../components/SimpleEditPage';
import withEditPage from '../hooks/EditPage';

const Form = (props: EditContainerProps) => {
  const { t } = useTranslation();

  return (
    <SimpleEditPage
      {...props}
    >
      <SimpleEditPage.Tab
        key='details'
        name={t('Common.tabs.details')}
      >
        <AwardForm
          {...props}
        />
      </SimpleEditPage.Tab>
    </SimpleEditPage>
  );
};

const Award: ComponentType<any> = withEditPage(Form, {
  id: 'awardId',
  onInitialize: (id) => (
    AwardsService
      .fetchOne(id)
      .then(({ data }) => data.award)
  ),
  onSave: (award) => (
    AwardsService
      .save(award)
      .then(({ data }) => data.award)
  ),
  resolveValidationError: ({ key, error }) => ({ [key]: error }),
  validate: (item) => Names.validate(item)
});

export default Award;
