// @flow

import { NestedAttributesTransform } from '@performant-software/shared-components';
import type { Nameable } from '@udcsl/shared';

/**
 * Class responsible for transforming name objects.
 */
class Names extends NestedAttributesTransform {
  /**
   * Returns the names payload keys.
   *
   * @returns {string[]}
   */
  getPayloadKeys(): Array<string> {
    return [
      'id',
      'name',
      'primary',
      '_destroy'
    ];
  }

  /**
   * Returns the names for the passed nameable to be sent on POST/PUT requests.
   *
   * @param nameable
   * @param collection
   *
   * @returns {*}
   */
  toPayload(nameable: Nameable, collection: string = 'names'): any {
    return super.toPayload(nameable, collection);
  }
}

const ProjectNamesTransform: Names = new Names();
export default ProjectNamesTransform;
