// @flow

import cx from 'classnames';
import React, { useCallback, useState, type ComponentType } from 'react';
import { useTranslation } from 'react-i18next';
import {
  HorizontalCards,
  LazyImage,
  PhotoViewer,
  Selectize,
  SelectizeImageHeader
} from '@performant-software/semantic-components';
import {
  Button,
  Card,
  Header,
  Image
} from 'semantic-ui-react';
import _ from 'underscore';
import styles from './ImageSelector.module.css';

type ItemType = {
  content_url: string,
  content_thumbnail_url: string
};

type Props = {
  items: Array<ItemType>,
  multiple?: boolean | number,
  onDrag?: (items: Array<any>) => void,
  onLoad: (params: any) => Promise<any>,
  onSave: (item: any) => void
};

const ImageSelector: ComponentType<any> = (props: Props) => {
  const [currentImage, setCurrentImage] = useState();
  const [modal, setModal] = useState(false);

  const { t } = useTranslation();

  /**
   * On drag callback for the HorizontalCards component.
   *
   * @type {(function(*, *): void)|*}
   */
  const onDrag = useCallback((dragIndex, hoverIndex) => {
    const temp = [...props.items];
    const item = temp[dragIndex];

    temp.splice(dragIndex, 1);
    temp.splice(hoverIndex, 0, item);

    if (props.onDrag) {
      props.onDrag(temp);
    }
  }, [props.items, props.onDrag]);

  /**
   * Calls the onSave prop and closes the modal.
   *
   * @type {(function(*): void)|*}
   */
  const onSave = useCallback((item) => {
    props.onSave(item);
    setModal(false);
  }, [props.onSave]);

  /**
   * Renders the Selectize header component.
   *
   * @type {function({onItemClick: *, selectedItem: *, selectedItems: *})}
   */
  const renderHeader = useCallback(({ onItemClick, selectedItem, selectedItems }) => (
    <SelectizeImageHeader
      isSelected={(item) => item === selectedItem}
      items={selectedItems}
      onItemClick={onItemClick}
      renderImage={(item) => item.content_thumbnail_url}
      renderHeader={(item) => item.label}
      selectedItem={selectedItem}
      selectedItems={selectedItems}
    />
  ), []);

  /**
   * Renders the Selectize items.
   *
   * @type {function({isSelected: *, items: *, onSelect: *})}
   */
  const renderItems = useCallback(({ isSelected, items, onSelect }) => (
    <Card.Group
      itemsPerRow={4}
    >
      { _.map(items, (item, index) => (
        <Card
          className={styles.card}
          key={index}
          link
          onClick={() => onSelect(item)}
        >
          { item.content_thumbnail_url && (
            <Image
              label={isSelected(item) ? { color: 'green', corner: 'right', icon: 'checkmark' } : undefined}
              src={item.content_thumbnail_url}
            />
          )}
          { !item.content_thumbnail_url && (
            <div
              className={styles.lazyImageContainer}
            >
              <LazyImage />
            </div>
          )}
        </Card>
      ))}
    </Card.Group>
  ), []);

  return (
    <div
      className={styles.imageSelector}
    >
      <Button
        basic
        className={styles.button}
        content={t('ImageSelector.labels.addRemove')}
        icon='images outline'
        onClick={() => setModal(true)}
      />
      <HorizontalCards
        cardClassName={cx(styles.ui, styles.card)}
        cardsClassName={cx(styles.ui, styles.cards)}
        items={props.items}
        onClick={(image) => setCurrentImage(image)}
        onDrag={onDrag}
        renderImage={(item) => item.content_thumbnail_url}
      />
      <PhotoViewer
        image={currentImage && currentImage.content_iiif_url}
        onClose={() => setCurrentImage(null)}
        open={!!currentImage}
      />
      { modal && (
        <Selectize
          className={styles.imageSelectorModal}
          collectionName='media_contents'
          multiple={props.multiple}
          onLoad={props.onLoad}
          onSave={onSave}
          onClose={() => setModal(false)}
          renderHeader={renderHeader}
          renderItems={renderItems}
          selectedItems={props.items}
          title={(
            <Header
              content={t('ImageSelector.labels.addRemove')}
              subheader={_.isNumber(props.multiple)
                ? t('ImageSelector.labels.subheader', { max: props.multiple })
                : undefined}
            />
          )}
        />
      )}
    </div>
  );
};

// $FlowIssue - Ignoring default props error
ImageSelector.defaultProps = {
  multiple: false
};

export default ImageSelector;
