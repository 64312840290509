// @flow

import React, { type ComponentType, type Node } from 'react';
import { Navigate } from 'react-router-dom';
import AuthenticationService from '../services/Authentication';
import UserContext from '../contexts/User';

type Props = {
  children: Array<Node>
};

const AuthenticatedRoute: ComponentType<any> = ({ children }: Props) => {
  if (!AuthenticationService.isAuthenticated()) {
    return <Navigate to='/' />;
  }

  const user = AuthenticationService.getUser();

  return (
    <UserContext.Provider
      value={user}
    >
      { children }
    </UserContext.Provider>
  );
};

export default AuthenticatedRoute;
