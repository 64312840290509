// @flow

import React, { type ComponentType } from 'react';
import { withTranslation } from 'react-i18next';
import { Form } from 'semantic-ui-react';
import NamesList from './NamesList';

const AwardForm: ComponentType<any> = withTranslation()((props) => (
  <>
    <NamesList
      items={props.item.names}
      onDelete={props.onDeleteChildAssociation}
      onSave={props.onSaveChildAssociation}
    />
    <Form.TextArea
      error={props.isError('description')}
      label={props.t('Award.labels.description')}
      onChange={props.onTextInputChange.bind(this, 'description')}
      required={props.isRequired('description')}
      value={props.item.description || ''}
    />
  </>
));

export default AwardForm;
