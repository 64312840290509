// @flow

import { useParams } from 'react-router-dom';

type Eventable = () => {
  eventableId: ?number,
  eventableType: ?string
};

const useEventable: Eventable = () => {
  const params = useParams();

  let eventableId;
  let eventableType;

  if (params.projectId) {
    eventableId = params.projectId;
    eventableType = 'Project';
  }

  return {
    eventableId,
    eventableType
  };
};

export default useEventable;
