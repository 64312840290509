// @flow

import cx from 'classnames';
import React, { useCallback, useMemo, type ComponentType } from 'react';
import { useLocation } from 'react-router-dom';
import { Breadcrumb } from 'semantic-ui-react';
import _ from 'underscore';
import BreadcrumbItem from './BreadcrumbItem';
import styles from './Breadcrumbs.module.css';

const URL_DELIMITER = '/';

const Breadcrumbs: ComponentType<any> = () => {
  const { pathname } = useLocation();

  /**
   * Returns true if the passed string contains only digits.
   *
   * @type {function(*): boolean}
   */
  const isNumeric = useCallback((str) => /^\d+$/.test(str), []);

  /**
   * Sets the items to display based on the URL path.
   *
   * @type {[]}
   */
  const items = useMemo(() => {
    const value = [];

    const path = pathname.split(URL_DELIMITER).splice(1);

    for (let i = 0; i < path.length; i += 1) {
      const key = path[i];
      const id = path[i + 1];
      const url = path.slice(0, i + 1).join(URL_DELIMITER);

      /*
       * If the item in the path is non-numeric, we'll add it as the item label.
       * If the next item is numeric, we'll add it as the ID.
       * If the next item is non-numeric, it'll be added as a separate label on the next iteration.
       */
      if (!isNumeric(key)) {
        const item = { key, url, id: undefined };

        if (isNumeric(id)) {
          item.id = id;
        }

        value.push(item);
      }
    }

    return value;
  }, [pathname]);

  /**
   * Returns true if there are more items to display.
   *
   * @type {function(*): boolean}
   */
  const hasMore = useCallback((index) => index < (items.length - 1), [items]);

  return (
    <Breadcrumb
      className={cx(styles.ui, styles.breadcrumbs)}
      size='large'
    >
      { _.map(items, (item, index) => (
        <>
          <BreadcrumbItem
            active={!hasMore(index)}
            id={item.id}
            name={item.key}
            url={item.url}
          />
          { hasMore(index) && (
            <Breadcrumb.Divider
              icon='right chevron'
            />
          )}
        </>
      ))}
    </Breadcrumb>
  );
};

export default Breadcrumbs;
