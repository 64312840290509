// @flow

import { ReferencesTransform } from '@performant-software/controlled-vocabulary';
import { BaseTransform } from '@performant-software/shared-components';
import type { Project as ProjectType } from '@udcsl/shared';
import Citations from './Citations';
import Images from './Images';
import Names from './Names';
import Participations from './Participantions';

/**
 * Class responsible for transforming project objects.
 */
class Project extends BaseTransform {
  /**
   * Returns the array of project payload keys.
   *
   * @returns {*[]}
   */
  getPayloadKeys(): Array<string> {
    return [
      'description',
      'typology',
      'size',
      'population_density',
      'gross_floor_area',
      'floor_area_ratio',
      'bibliography',
      'place_name',
      'latitude',
      'longitude',
      'site_description',
      'visibility',
      'featured'
    ];
  }

  /**
   * Returns the project parameter name.
   *
   * @returns {string}
   */
  getParameterName(): string {
    return 'project';
  }

  /**
   * Returns the passed project as a dropdown option.
   *
   * @param project
   *
   * @returns {{text, value, key}}
   */
  toDropdown(project: ProjectType): any {
    return {
      key: project.id,
      value: project.id,
      text: project.name
    };
  }

  /**
   * Returns the passed project as a payload for POST/PUT requests.
   *
   * @param project
   *
   * @returns {{[p: string]: {[p: string]: *}}}
   */
  toPayload(project: ProjectType): any {
    return super.toPayload(project, {
      ...Citations.toPayload(project),
      ...Images.toPayload(project),
      ...Names.toPayload(project),
      ...Participations.toPayload(project, 'organization_participants'),
      ...Participations.toPayload(project, 'person_participants'),
      ...ReferencesTransform.toPayload(project, 'project_type'),
      ...ReferencesTransform.toPayload(project, 'keywords'),
      ...ReferencesTransform.toPayload(project, 'stages'),
      ...ReferencesTransform.toPayload(project, 'community_infrastructure')
    });
  }
}

const ProjectTransform: Project = new Project();
export default ProjectTransform;
