// @flow

import { ReferenceCodeFormDropdown } from '@performant-software/controlled-vocabulary';
import { LazyMedia } from '@performant-software/semantic-components';
import React, { type ComponentType } from 'react';
import { Button, Form, Item } from 'semantic-ui-react';
import { withTranslation } from 'react-i18next';
import { VisibilityOptions } from '../constants/Visibility';

const FileUpload: ComponentType<any> = withTranslation()((props) => (
  <Item
    className='file-upload'
  >
    <Item.Image>
      <LazyMedia
        contentType={props.item.content_type}
        dimmable={false}
        src={props.item.content_url}
      />
    </Item.Image>
    <Item.Content>
      <Form.Input
        error={props.isError('label')}
        label={props.t('FileUpload.labels.label')}
        onChange={props.onTextInputChange.bind(this, 'label')}
        required={props.isRequired('label')}
        value={props.item.label}
      />
      <Form.TextArea
        error={props.isError('copyright')}
        label={props.t('FileUpload.labels.copyright')}
        onChange={props.onTextInputChange.bind(this, 'copyright')}
        required={props.isRequired('copyright')}
        value={props.item.copyright}
      />
      <ReferenceCodeFormDropdown
        error={props.isError('file_type')}
        label={props.t('FileUpload.labels.fileType')}
        required={props.isRequired('file_type')}
        onChange={(fileType) => props.onSetState({ file_type: fileType })}
        referenceTable='file_type'
        value={props.item.file_type}
      />
      <Form.Dropdown
        error={props.isError('visibility')}
        label={props.t('FileUpload.labels.visibility')}
        onChange={props.onTextInputChange.bind(this, 'visibility')}
        options={VisibilityOptions}
        required={props.isRequired('visibility')}
        selection
        selectOnBlur={false}
        value={props.item.visibility}
      />
      <Button
        basic
        color='red'
        icon='trash'
        onClick={props.onDelete}
      />
      { props.renderStatus() }
    </Item.Content>
  </Item>
));

export default FileUpload;
