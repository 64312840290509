// @flow

import { ReferenceCodeFormDropdown } from '@performant-software/controlled-vocabulary';
import React, { type ComponentType } from 'react';
import { withTranslation } from 'react-i18next';
import { Form } from 'semantic-ui-react';

const OrganizationForm: ComponentType<any> = withTranslation()((props) => (
  <>
    <Form.Input
      autoFocus
      error={props.isError('name')}
      label={props.t('Organization.labels.name')}
      onChange={props.onTextInputChange.bind(this, 'name')}
      required={props.isRequired('name')}
      value={props.item.name || ''}
    />
    <ReferenceCodeFormDropdown
      error={props.isError('organization_type')}
      label={props.t('Organization.labels.type')}
      required={props.isRequired('organization_type')}
      onChange={(organizationType) => props.onSetState({ organization_type: organizationType })}
      referenceTable='organization_types'
      value={props.item.organization_type}
    />
    <Form.TextArea
      error={props.isError('description')}
      label={props.t('Organization.labels.description')}
      onChange={props.onTextInputChange.bind(this, 'description')}
      required={props.isRequired('description')}
      value={props.item.description || ''}
    />
  </>
));

export default OrganizationForm;
