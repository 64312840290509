// @flow

import _ from 'underscore';
import i18n from '../i18n/i18n';

const Visibility = {
  private: 'private',
  internal: 'internal',
  public: 'public'
};

const getVisibilityLabel: (visibility: string) => string = (visibility) => i18n.t(`Visibility.labels.${visibility}`);

const VisibilityOptions: any = _.map(_.values(Visibility), (v) => ({
  key: v,
  value: v,
  text: getVisibilityLabel(v)
}));

export {
  getVisibilityLabel,
  Visibility,
  VisibilityOptions
};
