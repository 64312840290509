// @flow

import AwardsService from './Awards';
import EventsService from './Events';
import MediaContentsService from './MediaContents';
import OrganizationsService from './Organizations';
import PeopleService from './People';
import ProjectAwardsService from './ProjectAwards';
import ProjectsService from './Projects';
import UsersService from './Users';

const Services = {
  awards: 'awards',
  events: 'events',
  media_contents: 'media_contents',
  organizations: 'organizations',
  people: 'people',
  project_awards: 'project_awards',
  projects: 'projects',
  users: 'users'
};

type OnLoadType = (name: string, id: number, params?: any) => Promise<any>;

/**
 * Returns a promise to fetch the identifier for the passed name and ID.
 *
 * @param name
 * @param id
 * @param params
 *
 * @returns {*}
 */
const onLoad: OnLoadType = (name: string, id: number, params: any = {}) => {
  let promise;

  switch (name) {
    case Services.awards:
      promise = AwardsService
        .fetchOne(id).then(({ data }) => data.award?.name);
      break;

    case Services.events:
      promise = EventsService
        .fetchOne(id)
        .then(({ data }) => data.event?.label);
      break;

    case Services.media_contents:
      promise = MediaContentsService
        .fetchOne(id)
        .then(({ data }) => data.media_content?.label);
      break;

    case Services.organizations:
      promise = OrganizationsService
        .fetchOne(id)
        .then(({ data }) => data.organization?.name);
      break;

    case Services.people:
      promise = PeopleService
        .fetchOne(id)
        .then(({ data }) => data.person?.name);
      break;

    case Services.project_awards:
      promise = ProjectAwardsService.fetchOne(id).then(({ data }) => (
        params.awardId
          ? data.project_award?.project?.name
          : data.project_award?.award?.name
      ));
      break;

    case Services.projects:
      promise = ProjectsService
        .fetchOne(id)
        .then(({ data }) => data.project?.name);
      break;

    case Services.users:
      promise = UsersService
        .fetchOne(id).then(({ data }) => data.user?.name);
      break;

    default:
      promise = Promise.resolve();
  }

  return promise;
};

export default {
  onLoad
};
