// @flow

import { ReferencesTransform } from '@performant-software/controlled-vocabulary';
import { NestedAttributesTransform } from '@performant-software/shared-components';
import type {
  Organization,
  Person,
  Project,
  ProjectAward
} from '@udcsl/shared';
import _ from 'underscore';

type Participateable = Person | Organization | Project | ProjectAward;

/**
 * Class responsible for transforming person organizations nested attributes.
 */
class Participantions extends NestedAttributesTransform {
  /**
   * Returns the person organizations payload keys.
   *
   * @returns {string[]}
   */
  getPayloadKeys(): Array<string> {
    return [
      'id',
      'participant_id',
      'participant_type',
      'participateable_id',
      'participateable_type',
      'description',
      '_destroy'
    ];
  }

  /**
   * Returns the person_organizations for the passed person or organization to be sent on POST/PUT requests.
   *
   * @param item
   * @param collection
   *
   * @returns {*}
   */
  toPayload(item: Participateable, collection: string): any {
    return {
      [collection]: _.map(
        item[collection],
        (record, index) => ({
          ..._.pick(record, this.getPayloadKeys()),
          ...ReferencesTransform.toPayload(record, 'roles'),
          order: index
        })
      )
    };
  }
}

const PersonOrganizationsTransform: Participantions = new Participantions();
export default PersonOrganizationsTransform;
