// @flow

import { NestedAttributesTransform } from '@performant-software/shared-components';

/**
 * Class responsible for transforming event_media_content objects.
 */
class EventMediaContents extends NestedAttributesTransform {
  /**
   * Returns the event media contents payload keys.
   *
   * @returns {string[]}
   */
  getPayloadKeys(): Array<string> {
    return [
      'id',
      'media_content_id',
      '_destroy'
    ];
  }

  /**
   * Returns the event media contents for the passed project to be sent on POST/PUT requests.
   *
   * @param project
   * @param collection
   *
   * @returns {*}
   */
  toPayload(project: any, collection: string = 'event_media_contents'): any {
    return super.toPayload(project, collection);
  }
}

const EventMediaContentsTransform: EventMediaContents = new EventMediaContents();
export default EventMediaContentsTransform;
