// @flow

import React, { type ComponentType } from 'react';
import { withTranslation } from 'react-i18next';
import { Form, Modal } from 'semantic-ui-react';

const NameModal: ComponentType<any> = withTranslation()((props) => (
  <Modal
    as={Form}
    centered={false}
    open
  >
    <Modal.Header
      content={props.item.id
        ? props.t('NameModal.title.edit')
        : props.t('NameModal.title.add')}
    />
    <Modal.Content>
      <Form.Input
        autoFocus
        error={props.isError('name')}
        label={props.t('NameModal.labels.name')}
        onChange={props.onTextInputChange.bind(this, 'name')}
        required={props.isRequired('name')}
        value={props.item.name}
      />
      <Form.Checkbox
        checked={props.item.primary}
        error={props.isError('primary')}
        label={props.t('NameModal.labels.primary')}
        onChange={props.onCheckboxInputChange.bind(this, 'primary')}
        required={props.isRequired('primary')}
      />
    </Modal.Content>
    { props.children }
  </Modal>
));

export default NameModal;
