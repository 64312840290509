// @flow

import { BooleanIcon, ListTable } from '@performant-software/semantic-components';
import React, { type ComponentType } from 'react';
import { useTranslation } from 'react-i18next';
import { useLocation, useNavigate } from 'react-router-dom';
import EventsService from '../services/Events';
import useEventable from '../hooks/Eventable';
import { displayDate } from '@udcsl/shared';

const Events: ComponentType<any> = () => {
  const { eventableId, eventableType } = useEventable();
  const location = useLocation();
  const navigate = useNavigate();
  const { t } = useTranslation();

  return (
    <ListTable
      actions={[{
        name: 'edit',
        onClick: (item) => navigate(`${item.id}`)
      }, {
        name: 'delete'
      }]}
      addButton={{
        location: 'top',
        onClick: () => navigate('new')
      }}
      collectionName='events'
      columns={[{
        name: 'label',
        label: t('Events.columns.label'),
        sortable: true
      }, {
        name: 'description',
        className: 'truncate',
        label: t('Events.columns.description'),
        sortable: true
      }, {
        name: 'start_date',
        label: t('Events.columns.startDate'),
        resolve: (e) => displayDate(e.start_date, e.start_date_display),
        sortable: true
      }, {
        name: 'end_date',
        label: t('Events.columns.endDate'),
        resolve: (e) => displayDate(e.end_date, e.end_date_display),
        sortable: true
      }, {
        name: 'include_dates',
        label: t('Events.columns.includeDates'),
        render: (e) => <BooleanIcon value={e.include_dates} />,
        sortable: true
      }]}
      defaultSort='start_date'
      onLoad={(params) => (
        EventsService.fetchAll({
          ...params,
          eventable_id: eventableId,
          eventable_type: eventableType
        })
      )}
      onDelete={(event) => EventsService.delete(event)}
      saved={location.state && location.state.saved}
      session={{
        key: 'EventsList',
        storage: sessionStorage
      }}
    />
  );
};

export default Events;
