import { Date as DateUtils } from '@performant-software/shared-components';

const displayDate = (dateString: string, dateDisplay: string) => {
  if (dateString) {
    // handle date display options
    const opts: Intl.DateTimeFormatOptions = { year: 'numeric', month: 'numeric', day: 'numeric' };
    // "month" means month and year, "year" means only year. ("day" means day, month, and year)
    if (['month', 'year'].includes(dateDisplay)) { delete opts.day; }
    if (dateDisplay === 'year') { delete opts.month; }
    return DateUtils.formatDate(dateString, 'en-US', opts);
  } return null;
};

export default displayDate;
