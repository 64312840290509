// @flow

import { Reference as ReferenceUtils } from '@performant-software/controlled-vocabulary';
import { EmbeddedList } from '@performant-software/semantic-components';
import type { EditContainerProps } from '@performant-software/shared-components/types';
import React, { type ComponentType } from 'react';
import { useTranslation } from 'react-i18next';
import OrganizationForm from '../components/OrganizationForm';
import OrganizationsService from '../services/Organizations';
import ParticipationModal from '../components/ParticipationModal';
import PeopleService from '../services/People';
import Person from '../transforms/Person';
import PersonModal from '../components/PersonModal';
import SimpleEditPage from '../components/SimpleEditPage';
import withEditPage from '../hooks/EditPage';

const Form = (props: EditContainerProps) => {
  const { t } = useTranslation();

  return (
    <SimpleEditPage
      {...props}
    >
      <SimpleEditPage.Tab
        key='details'
        name={t('Common.tabs.details')}
      >
        <OrganizationForm
          {...props}
        />
      </SimpleEditPage.Tab>
      <SimpleEditPage.Tab
        key='people'
        name={t('Organization.tabs.people')}
      >
        <EmbeddedList
          actions={[{
            name: 'edit'
          }, {
            name: 'delete'
          }]}
          columns={[{
            name: 'first_name',
            label: t('Organization.people.columns.first_name'),
            resolve: (po) => po.participant?.first_name,
            sortable: true
          }, {
            name: 'last_name',
            label: t('Organization.people.columns.last_name'),
            resolve: (po) => po.participant?.last_name,
            sortable: true
          }, {
            name: 'roles',
            label: t('Organization.people.columns.roles'),
            resolve: (po) => ReferenceUtils.getViewValue(po.roles),
            sortable: true
          }]}
          items={props.item.person_participants}
          modal={{
            component: ParticipationModal,
            props: {
              attribute: 'participant_id',
              collectionName: 'people',
              label: t('Organization.labels.person'),
              modal: {
                component: PersonModal,
                onSave: (person) => (
                  PeopleService
                    .save(person)
                    .then(({ data }) => data.person)
                ),
                props: {
                  required: ['first_name', 'last_name']
                }
              },
              onSearch: (search) => PeopleService.fetchAll({ search }),
              renderItem: (person) => person.name,
              renderOption: (person) => Person.toDropdown(person),
              required: ['participant_id', 'roles'],
              rolesReferenceTable: 'person_organization_roles',
              title: {
                add: t('Organization.people.title.add'),
                edit: t('Organization.people.title.edit')
              }
            }
          }}
          onDelete={props.onDeleteChildAssociation.bind(this, 'person_participants')}
          onSave={props.onSaveChildAssociation.bind(this, 'person_participants')}
        />
      </SimpleEditPage.Tab>
    </SimpleEditPage>
  );
};

const Organization: ComponentType<any> = withEditPage(Form, {
  id: 'organizationId',
  onInitialize: (id) => (
    OrganizationsService
      .fetchOne(id)
      .then(({ data }) => data.organization)
  ),
  onSave: (organization) => (
    OrganizationsService
      .save(organization)
      .then(({ data }) => data.organization)
  ),
  required: ['name', 'organization_type']
});

export default Organization;
