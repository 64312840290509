// @flow

import { ReferenceCodeFormDropdown, Reference as ReferenceUtils } from '@performant-software/controlled-vocabulary';
import { GoogleScript } from '@performant-software/shared-components';
import {
  BibliographyList,
  EmbeddedList,
  GoogleMap,
  GooglePlacesSearch,
  HorizontalCards,
  PhotoViewer
} from '@performant-software/semantic-components';
import { ImageLocations } from '@udcsl/shared';
import React, { type ComponentType, useCallback, useState } from 'react';
import { withTranslation } from 'react-i18next';
import uuid from 'react-uuid';
import {
  Button,
  Form,
  Header,
  Icon
} from 'semantic-ui-react';
import _ from 'underscore';
import ImageSelector from '../components/ImageSelector';
import Images from '../utils/Images';
import MediaContentsService from '../services/MediaContents';
import Names from '../utils/Names';
import NamesList from '../components/NamesList';
import NumberPicker from '../components/NumberPicker';
import Organization from '../transforms/Organization';
import OrganizationModal from '../components/OrganizationModal';
import OrganizationsService from '../services/Organizations';
import ParticipationModal from '../components/ParticipationModal';
import PeopleService from '../services/People';
import Person from '../transforms/Person';
import PersonModal from '../components/PersonModal';
import ProjectsService from '../services/Projects';
import SimpleEditPage from '../components/SimpleEditPage';
import styles from './Project.module.css';
import { Visibility, VisibilityOptions } from '../constants/Visibility';
import withEditPage from '../hooks/EditPage';

const GOOGLE_SCRIPT_LIBRARIES = ['places'];

const ProjectForm = withTranslation()((props) => {
  const [currentImage, setCurrentImage] = useState();

  /**
   * Creates a new image with the passed location.
   *
   * @type {function(*, *): {uid: string, media_content_id: *, media_content: *, location: *}}
   */
  const createImage = useCallback((item, location) => ({
    media_content_id: item.id,
    media_content: item,
    uid: uuid(),
    location
  }), []);

  /**
   * Returns an image (if exists) with the passed location and media_content_id.
   *
   * @type {function(*, *): *}
   */
  const findImage = useCallback((item, location) => _.findWhere(props.item.images, {
    media_content_id: item.id,
    location
  }), [props.item.images]);

  /**
   * Returns the images for the passed location.
   *
   * @type {function(*): *}
   */
  const getImages = useCallback((location) => (
    _.chain(props.item.images)
      .filter((image) => image.location === location && !image._destroy)
      .map((i) => i.media_content)
      .value()
  ), [props.item.images]);

  /**
   * Sets the newly ordered images on the state.
   *
   * @type {function(*, *): *}
   */
  const onImageDrag = useCallback((location, items) => (
    props.onSetState({
      images: [
        ..._.map(items, (item) => findImage(item, location)),
        ..._.filter(props.item.images, (item) => item.location !== location)
      ]
    })
  ), [props.item.images]);

  /**
   * Fetches the images for the current project.
   *
   * @type {function(*): Promise<any>}
   */
  const onImageLoad = useCallback((params) => (
    MediaContentsService.fetchAll({
      ...params,
      project_id: props.item.id,
      per_page: 8,
      visibility: Visibility.public
    })
  ), [props.item]);

  /**
   * Sets the newly selected images on the state for the passed location.
   *
   * @type {(function(*, *): void)|*}
   */
  const onImageSelection = useCallback((location, items) => (
    props.onMultiAddChildAssociations('images', [
      ..._.map(items, (item) => findImage(item, location) || createImage(item, location)),
      ..._.filter(props.item.images, (item) => item.location !== location)
    ])
  ), [props.item.images]);

  /**
   * Sets the location information based on the passed data.
   *
   * @type {function(*): *}
   */
  const onLocationSelection = useCallback((data) => props.onSetState({
    place_name: data.name,
    latitude: data.lat,
    longitude: data.lng,
    place_images: data.result?.photos
  }), []);

  return (
    <SimpleEditPage
      {...props}
      className={styles.projectForm}
    >
      <SimpleEditPage.Tab
        key='details'
        name={props.t('Common.tabs.details')}
      >
        <Form.Field
          className={styles.featured}
        >
          <Button
            color={props.item.featured ? 'blue' : undefined}
            onClick={() => props.onSetState({ featured: props.item.featured ? null : 1 })}
          >
            <Icon
              name='star'
            />
            { props.t('Project.labels.featured') }
          </Button>
          { props.item.featured && (
            <NumberPicker
              onChange={(featured) => props.onSetState({ featured })}
              value={props.item.featured}
            />
          )}
        </Form.Field>
        <Header
          content={props.t('Project.labels.projectNames')}
          style={{
            marginTop: 'unset'
          }}
        />
        <NamesList
          items={props.item.names}
          onDelete={props.onDeleteChildAssociation}
          onSave={props.onSaveChildAssociation}
        />
        <Form.TextArea
          error={props.isError('description')}
          label={props.t('Project.labels.description')}
          onChange={props.onTextInputChange.bind(this, 'description')}
          required={props.isRequired('description')}
          value={props.item.description || ''}
        />
        <Form.TextArea
          error={props.isError('typology')}
          label={props.t('Project.labels.typology')}
          onChange={props.onTextInputChange.bind(this, 'typology')}
          required={props.isRequired('typology')}
          value={props.item.typology || ''}
        />
        <ReferenceCodeFormDropdown
          error={props.isError('project_type')}
          label={props.t('Project.labels.type')}
          required={props.isRequired('project_type')}
          onChange={(projectType) => props.onSetState({ project_type: projectType })}
          referenceTable='project_types'
          value={props.item.project_type || []}
        />
        <ReferenceCodeFormDropdown
          error={props.isError('stages')}
          label={props.t('Project.labels.stages')}
          multiple
          required={props.isRequired('stages')}
          onChange={(stages) => props.onSetState({ stages })}
          referenceTable='project_stages'
          value={props.item.stages || []}
        />
        <Form.TextArea
          error={props.isError('site_description')}
          label={props.t('Project.labels.siteDescription')}
          onChange={props.onTextInputChange.bind(this, 'site_description')}
          required={props.isRequired('site_description')}
          value={props.item.site_description || ''}
        />
        <Form.Dropdown
          error={props.isError('visibility')}
          label={props.t('Project.labels.visibility')}
          onChange={props.onTextInputChange.bind(this, 'visibility')}
          options={VisibilityOptions}
          required={props.isRequired('visibility')}
          selection
          selectOnBlur={false}
          value={props.item.visibility}
        />
        <Form.TextArea
          error={props.isError('size')}
          label={props.t('Project.labels.size')}
          onChange={props.onTextInputChange.bind(this, 'size')}
          required={props.isRequired('size')}
          value={props.item.size || ''}
        />
        <Form.TextArea
          error={props.isError('population_density')}
          label={props.t('Project.labels.populationDensity')}
          onChange={props.onTextInputChange.bind(this, 'population_density')}
          required={props.isRequired('population_density')}
          value={props.item.population_density || ''}
        />
        <Form.TextArea
          error={props.isError('gross_floor_area')}
          label={props.t('Project.labels.grossFloorArea')}
          onChange={props.onTextInputChange.bind(this, 'gross_floor_area')}
          required={props.isRequired('gross_floor_area')}
          value={props.item.gross_floor_area || ''}
        />
        <Form.TextArea
          error={props.isError('floor_area_ratio')}
          label={props.t('Project.labels.floorAreaRatio')}
          onChange={props.onTextInputChange.bind(this, 'floor_area_ratio')}
          required={props.isRequired('floor_area_ratio')}
          value={props.item.floor_area_ratio || ''}
        />
        <ReferenceCodeFormDropdown
          error={props.isError('keywords')}
          label={props.t('Project.labels.keywords')}
          multiple
          required={props.isRequired('keywords')}
          onChange={(keywords) => props.onSetState({ keywords })}
          referenceTable='project_keywords'
          value={props.item.keywords || []}
        />
        <ReferenceCodeFormDropdown
          error={props.isError('community_infrastructure')}
          label={props.t('Project.labels.communityInfrastructure')}
          multiple
          required={props.isRequired('community_infrastructure')}
          onChange={(ci) => props.onSetState({ community_infrastructure: ci })}
          referenceTable='community_infrastructure'
          value={props.item.community_infrastructure || []}
        />
      </SimpleEditPage.Tab>
      <SimpleEditPage.Tab
        key='location'
        name={props.t('Project.tabs.location')}
      >
        <GoogleScript
          googleMapsApiKey={process.env.REACT_APP_GOOGLE_MAPS_API_KEY}
          libraries={GOOGLE_SCRIPT_LIBRARIES}
        >
          <Form.Field>
            <GooglePlacesSearch
              onLocationSelection={onLocationSelection}
            >
              <Form.Input
                autoFocus
                error={props.isError('place_name')}
                label={props.t('Project.labels.placeName')}
                placeholder=''
                onChange={props.onTextInputChange.bind(this, 'place_name')}
                required={props.isRequired('place_name')}
                value={props.item.place_name || ''}
              />
            </GooglePlacesSearch>
          </Form.Field>
          <Form.Input
            error={props.isError('latitude')}
            label={props.t('Project.labels.latitude')}
            onChange={props.onTextInputChange.bind(this, 'latitude')}
            required={props.isRequired('latitude')}
            value={props.item.latitude || ''}
          />
          <Form.Input
            error={props.isError('longitude')}
            label={props.t('Project.labels.longitude')}
            onChange={props.onTextInputChange.bind(this, 'longitude')}
            required={props.isRequired('longitude')}
            value={props.item.longitude || ''}
          />
          <GoogleMap
            defaultZoom={2}
            onDragEnd={({ lat, lng }) => props.onSetState({ latitude: lat, longitude: lng })}
            position={{ lat: props.item.latitude, lng: props.item.longitude }}
          />
          <HorizontalCards
            items={props.item.place_images}
            onClick={(image) => setCurrentImage(image)}
            renderImage={(image) => image.getUrl()}
          />
          <PhotoViewer
            image={currentImage && currentImage.getUrl()}
            onClose={() => setCurrentImage(null)}
            open={!!currentImage}
          />
        </GoogleScript>
      </SimpleEditPage.Tab>
      <SimpleEditPage.Tab
        key='organizations'
        name={props.t('Project.tabs.organizations')}
      >
        <EmbeddedList
          actions={[{
            name: 'edit'
          }, {
            name: 'delete'
          }]}
          columns={[{
            name: 'name',
            label: props.t('Project.organizations.columns.name'),
            resolve: (p) => p.participant?.name,
            sortable: true
          }, {
            name: 'type',
            label: props.t('Project.organizations.columns.type'),
            resolve: (p) => p.participant?.organization_type_view,
            sortable: true
          }, {
            name: 'roles',
            label: props.t('Project.organizations.columns.roles'),
            resolve: (p) => ReferenceUtils.getViewValue(p.roles),
            sortable: true
          }, {
            name: 'description',
            className: 'truncate',
            label: props.t('Project.organizations.columns.description'),
            sortable: true
          }]}
          items={props.item.organization_participants}
          modal={{
            component: ParticipationModal,
            props: {
              attribute: 'participant_id',
              collectionName: 'organizations',
              label: props.t('Project.labels.organization'),
              modal: {
                component: OrganizationModal,
                onSave: (organization) => (
                  OrganizationsService
                    .save(organization)
                    .then(({ data }) => data.organization)
                ),
                props: {
                  required: ['name', 'organization_type']
                }
              },
              onSearch: (search) => OrganizationsService.fetchAll({ search }),
              renderItem: (organization) => organization.name,
              renderOption: (organization) => Organization.toDropdown(organization),
              required: ['participant_id', 'roles'],
              rolesReferenceTable: 'project_organizations_roles',
              title: {
                add: props.t('Project.organizations.title.add'),
                edit: props.t('Project.organizations.title.edit')
              }
            }
          }}
          onDelete={props.onDeleteChildAssociation.bind(this, 'organization_participants')}
          onSave={props.onSaveChildAssociation.bind(this, 'organization_participants')}
        />
      </SimpleEditPage.Tab>
      <SimpleEditPage.Tab
        key='people'
        name={props.t('Project.tabs.people')}
      >
        <EmbeddedList
          actions={[{
            name: 'edit'
          }, {
            name: 'delete'
          }]}
          columns={[{
            name: 'first_name',
            label: props.t('Project.people.columns.first_name'),
            resolve: (p) => p.participant?.first_name,
            sortable: true
          }, {
            name: 'last_name',
            label: props.t('Project.people.columns.last_name'),
            resolve: (p) => p.participant?.last_name,
            sortable: true
          }, {
            name: 'profession',
            label: props.t('Project.people.columns.profession'),
            resolve: (p) => p.participant?.profession_view,
            sortable: true
          }, {
            name: 'roles',
            label: props.t('Project.people.columns.roles'),
            resolve: (p) => ReferenceUtils.getViewValue(p.roles),
            sortable: true
          }, {
            name: 'description',
            className: 'truncate',
            label: props.t('Project.people.columns.description'),
            sortable: true
          }]}
          items={props.item.person_participants}
          modal={{
            component: ParticipationModal,
            props: {
              attribute: 'participant_id',
              collectionName: 'people',
              label: props.t('Project.labels.person'),
              modal: {
                component: PersonModal,
                onSave: (person) => (
                  PeopleService
                    .save(person)
                    .then(({ data }) => data.person)
                ),
                props: {
                  required: ['first_name', 'last_name']
                }
              },
              onSearch: (search) => PeopleService.fetchAll({ search }),
              renderItem: (person) => person.name,
              renderOption: (person) => Person.toDropdown(person),
              required: ['participant_id', 'roles'],
              rolesReferenceTable: 'project_person_roles',
              title: {
                add: props.t('Project.people.title.add'),
                edit: props.t('Project.people.title.edit')
              }
            }
          }}
          onDelete={props.onDeleteChildAssociation.bind(this, 'person_participants')}
          onSave={props.onSaveChildAssociation.bind(this, 'person_participants')}
        />
      </SimpleEditPage.Tab>
      <SimpleEditPage.Tab
        key='bibliography'
        name={props.t('Common.tabs.bibliography')}
      >
        <BibliographyList
          items={props.item.citations}
          onDelete={props.onDeleteChildAssociation.bind(this, 'citations')}
          onSave={props.onSaveChildAssociation.bind(this, 'citations')}
          translateUrl={process.env.REACT_APP_TRANSLATE_URL}
        />
      </SimpleEditPage.Tab>
      <SimpleEditPage.Tab
        key='website'
        name={props.t('Project.tabs.webDisplay')}
      >
        <Form.Input
          label={props.t('Project.labels.heroImage')}
        >
          <ImageSelector
            items={getImages(ImageLocations.hero)}
            onDrag={onImageDrag.bind(this, ImageLocations.hero)}
            onLoad={onImageLoad.bind(this)}
            onSave={onImageSelection.bind(this, ImageLocations.hero)}
            projectId={props.item.id}
          />
        </Form.Input>
        <Form.Input
          label={props.t('Project.labels.overviewImages')}
        >
          <ImageSelector
            items={getImages(ImageLocations.overview)}
            multiple={2}
            onDrag={onImageDrag.bind(this, ImageLocations.overview)}
            onLoad={onImageLoad.bind(this)}
            onSave={onImageSelection.bind(this, ImageLocations.overview)}
            projectId={props.item.id}
          />
        </Form.Input>
        <Form.Input
          label={props.t('Project.labels.siteHeaderImage')}
        >
          <ImageSelector
            items={getImages(ImageLocations.siteHeader)}
            onDrag={onImageDrag.bind(this, ImageLocations.siteHeader)}
            onLoad={onImageLoad.bind(this)}
            onSave={onImageSelection.bind(this, ImageLocations.siteHeader)}
            projectId={props.item.id}
          />
        </Form.Input>
        <Form.Input
          label={props.t('Project.labels.siteFooterImages')}
        >
          <ImageSelector
            items={getImages(ImageLocations.siteFooter)}
            multiple={3}
            onDrag={onImageDrag.bind(this, ImageLocations.siteFooter)}
            onLoad={onImageLoad.bind(this)}
            onSave={onImageSelection.bind(this, ImageLocations.siteFooter)}
          />
        </Form.Input>
      </SimpleEditPage.Tab>
    </SimpleEditPage>
  );
});

const Project: ComponentType<any> = withEditPage(ProjectForm, {
  id: 'projectId',
  onInitialize: (id) => (
    ProjectsService
      .fetchOne(id)
      .then(({ data }) => data.project)
  ),
  onSave: (project) => (
    ProjectsService
      .save(project)
      .then(({ data }) => data.project)
  ),
  resolveValidationError: ({ key, error }) => ({ [key]: error }),
  required: [
    'description',
    'project_type',
    'stages',
    'typology',
    'visibility'
  ],
  defaults: {
    visibility: Visibility.private
  },
  validate: (project) => ({
    ...Images.validate(project),
    ...Names.validate(project)
  })
});

export default Project;
