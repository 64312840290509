// @flow

import { ListTable } from '@performant-software/semantic-components';
import React, { type ComponentType } from 'react';
import { useTranslation } from 'react-i18next';
import { useLocation, useNavigate } from 'react-router-dom';
import PeopleService from '../services/People';

const People: ComponentType<any> = () => {
  const location = useLocation();
  const navigate = useNavigate();
  const { t } = useTranslation();

  return (
    <ListTable
      actions={[{
        name: 'edit',
        onClick: (item) => navigate(`${item.id}`)
      }, {
        name: 'delete'
      }]}
      addButton={{
        location: 'top',
        onClick: () => navigate('new')
      }}
      collectionName='people'
      columns={[{
        name: 'first_name',
        label: t('People.columns.first_name'),
        sortable: true
      }, {
        name: 'last_name',
        label: t('People.columns.last_name'),
        sortable: true
      }, {
        name: 'profession',
        label: t('People.columns.profession'),
        resolve: (person) => person.profession_view,
        sortable: true
      }, {
        name: 'biography',
        className: 'truncate',
        label: t('People.columns.biography'),
        sortable: true
      }]}
      onLoad={(params) => PeopleService.fetchAll(params)}
      onDelete={(person) => PeopleService.delete(person)}
      saved={location.state && location.state.saved}
      session={{
        key: 'PeopleList',
        storage: sessionStorage
      }}
    />
  );
};

export default People;
