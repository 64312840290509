// @flow

import { ReferencesTransform } from '@performant-software/controlled-vocabulary';
import { BaseTransform } from '@performant-software/shared-components';
import type { Event as EventType } from '@udcsl/shared';
import EventMediaContents from './EventMediaContents';

/**
 * Class responsible for transforming event objects.
 */
class Event extends BaseTransform {
  /**
   * Returns the array of event payload keys.
   *
   * @returns {*[]}
   */
  getPayloadKeys(): Array<string> {
    return [
      'eventable_id',
      'eventable_type',
      'label',
      'description',
      'start_date',
      'start_date_display',
      'end_date',
      'end_date_display',
      'include_dates'
    ];
  }

  /**
   * Returns the event parameter name.
   *
   * @returns {string}
   */
  getParameterName(): string {
    return 'event';
  }

  /**
   * Returns the passed event as a payload for POST/PUT requests.
   *
   * @param event
   *
   * @returns {{[p: string]: {[p: string]: *}}}
   */
  toPayload(event: EventType): any {
    const payload = super.toPayload(event)[this.getParameterName()];

    return {
      [this.getParameterName()]: {
        ...payload,
        ...ReferencesTransform.toPayload(event, 'event_type'),
        ...EventMediaContents.toPayload(event)
      }
    };
  }
}

const EventTransform: Event = new Event();
export default EventTransform;
