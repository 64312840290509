// @flow

import React, { type AbstractComponent } from 'react';
import { Icon, Label } from 'semantic-ui-react';
import { getVisibilityLabel, Visibility } from '../constants/Visibility';

type Props = {
  visibility: string
};

const Colors = {
  [Visibility.public]: 'green',
  [Visibility.internal]: 'orange',
  [Visibility.private]: 'red'
};

const VisibilityLabel: AbstractComponent<any> = ({ visibility }: Props) => (
  <Label
    color={Colors[visibility]}
  >
    <Icon
      name='eye'
    />
    { getVisibilityLabel(visibility) }
  </Label>
);

export default VisibilityLabel;
