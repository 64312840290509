// @flow

import { NestedAttributesTransform } from '@performant-software/shared-components';
import type { Project as ProjectType } from '@udcsl/shared';

/**
 * Class responsible for transforming image objects.
 */
class Images extends NestedAttributesTransform {
  /**
   * Returns the image payload keys.
   *
   * @returns {string[]}
   */
  getPayloadKeys(): Array<string> {
    return [
      'id',
      'media_content_id',
      'location',
      '_destroy'
    ];
  }

  /**
   * Returns the images for the passed project to be sent on POST/PUT requests.
   *
   * @param project
   * @param collection
   *
   * @returns {*}
   */
  toPayload(project: ProjectType, collection: string = 'images'): any {
    return super.toPayload(project, collection);
  }
}

const WebImagesTransform: Images = new Images();
export default WebImagesTransform;
