import { BaseService, BaseTransform } from '@performant-software/shared-components';
import _ from 'underscore';

interface Opts {
  baseURL?: string,
  functions?: { [key: string]: () => any }
  path: string,
  transform?: any
}

interface Service {
  fetchAll(params?: any): Promise<any>,
  fetchOne(id: number): Promise<any>,
  getBaseUrl(): string
  getTransform(): typeof BaseTransform
}

/**
 *
 * @param opts
 */
const createClass = (opts: Opts) => {
  class InnerClass extends BaseService {
    /**
     * Returns the service base URL.
     */
    getBaseUrl() {
      return `${opts.baseURL ? opts.baseURL : ''}${opts.path}`;
    }

    /**
     * Returns the service transform.
     */
    getTransform() {
      return opts.transform;
    }
  }

  // Define the functions dynamically on the class
  _.each(_.keys(opts.functions), (functionName) => {
    InnerClass.prototype[functionName] = opts.functions && opts.functions[functionName];
  });

  return InnerClass;
};

/**
 *
 *
 * @param opts
 */
const createService = (opts: Opts) => {
  const ServiceClass = createClass(opts);
  return new ServiceClass() as Service;
};

export default createService;
