// @flow

import { BaseService } from '@performant-software/shared-components';
import { createService, type MediaContent as MediaContentType } from '@udcsl/shared';
import MediaContent from '../transforms/MediaContent';

const MediaContentsService: typeof BaseService = createService({
  path: '/api/media_contents',
  functions: {
    upload: (mediaContents: Array<MediaContentType>) => {
      // $FlowFixMe
      const url = `${this.getBaseUrl()}/upload`;

      // $FlowFixMe
      const transform = this.getTransform();
      const payload = transform.toUploadPayload(mediaContents);

      // $FlowFixMe
      return this.getAxios().post(url, payload, this.getConfig());
    }
  },
  transform: MediaContent
});

export default MediaContentsService;
