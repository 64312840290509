// @flow

import React, { type AbstractComponent } from 'react';
import { useTranslation } from 'react-i18next';
import { Icon, Label } from 'semantic-ui-react';

type Props = {
  value: number
};

const FeaturedLabel: AbstractComponent<any> = (props: Props) => {
  const { t } = useTranslation();
  const { value } = props;

  return (
    <Label
      color='blue'
    >
      <Icon
        name='star'
      />
      { t('FeaturedLabel.labels.featured', { value }) }
    </Label>
  );
};

export default FeaturedLabel;
