// @flow

/* eslint max-len: ["off"] */

import React, { type ComponentType } from 'react';

const Logo: ComponentType<any> = () => (
  <svg
    viewBox='0 0 67 100'
    height='75px'
  >
    <title>GSD Logo</title>
    <g>
      {}
      <path
        fill='#FFFFFF'
        d='M39.3119841,0 L34.9995238,32.8989362 L8.83761905,12.7659574 L0,79.2819149 L27.2307143,99.9680851 L31.5538095,67.25 L57.6838095,87.4414894 L66.4735714,20.8989362 L39.3119841,0 L39.3119841,0 Z M40.1255556,1.97340426 L65.3409524,21.3670213 L61.1720635,52.9308511 L35.9938889,33.6648936 L35.972619,33.6489362 L40.1255556,1.97340426 L40.1255556,1.97340426 Z M9.65119048,14.7287234 L34.3614286,33.7553191 L5.57269841,45.4468085 L9.65119048,14.7287234 L9.65119048,14.7287234 Z M26.4171429,98.0159574 L1.13793651,78.8085106 L5.3334127,47.2180851 L30.554127,66.6808511 L26.4171429,98.0159574 L26.4171429,98.0159574 Z M30.7349206,65.4734043 L30.7189683,65.462766 L6.02468254,46.4095745 L34.7602381,34.7446809 L34.7761905,34.7340426 L32.7874603,49.8031915 L30.7349206,65.4734043 L30.7349206,65.4734043 Z M31.7665079,65.6489362 L33.5159524,52.4095745 L35.8130952,34.8670213 L35.8343651,34.8776596 L60.438254,53.7074468 L31.7824603,65.643617 L31.7665079,65.6489362 L31.7665079,65.6489362 Z M56.8702381,85.4734043 L32.3780159,66.5478723 L60.9434127,54.6489362 L56.8702381,85.4734043 L56.8702381,85.4734043 Z'
        id='GSD-Logo'
      />
    </g>
  </svg>
);

export default Logo;
