// @flow

import { ReferencesTransform } from '@performant-software/controlled-vocabulary';
import { Attachments, FormDataTransform, String } from '@performant-software/shared-components';
import type { MediaContent as MediaContentType } from '@udcsl/shared';
import _ from 'underscore';
import Citations from './Citations';

/**
 * Class responsible for transforming media_content objects.
 */
class MediaContent extends FormDataTransform {
  /**
   * Returns the array of media_content payload keys.
   *
   * @returns {*[]}
   */
  getPayloadKeys(): Array<string> {
    return [
      'project_id',
      'label',
      'caption',
      'description',
      'external_identifiers',
      'copyright',
      'visibility',
      'primary',
      'timeframe'
    ];
  }

  /**
   * Returns the media_content parameter name.
   *
   * @returns {string}
   */
  getParameterName(): string {
    return 'media_content';
  }

  /**
   * Returns the passed media_content record to be sent on PUT/POST requests.
   *
   * @param mediaContent
   *
   * @returns {*}
   */
  toPayload(mediaContent: MediaContentType): any {
    const formData = super.toPayload(mediaContent);
    Attachments.toPayload(formData, this.getParameterName(), mediaContent, 'content');
    Citations.toFormData(formData, this.getParameterName(), mediaContent);
    ReferencesTransform.toFormData(formData, this.getParameterName(), mediaContent, 'file_type');
    ReferencesTransform.toFormData(formData, this.getParameterName(), mediaContent, 'keywords');

    return formData;
  }

  /**
   * Returns the passed array of media contents as FormData for multi-record upload.
   *
   * @param mediaContents
   *
   * @returns {FormData}
   */
  toUploadPayload(mediaContents: Array<MediaContentType>): FormData {
    const formData = new FormData();

    _.each(mediaContents, (mediaContent, index) => {
      _.each(this.getPayloadKeys(), (key) => {
        formData.append(`media_contents[${index}][${key}]`, String.toString(mediaContent[key]));
      });

      Attachments.toPayload(formData, `media_contents[${index}]`, mediaContent, 'content');
    });

    return formData;
  }
}

const MediaContentTransform: MediaContent = new MediaContent();
export default MediaContentTransform;
