// @flow

import React, { type ComponentType } from 'react';
import { withTranslation } from 'react-i18next';
import { Form, Message } from 'semantic-ui-react';
import SimpleEditPage from '../components/SimpleEditPage';
import UsersService from '../services/Users';
import withEditPage from '../hooks/EditPage';

const UserForm = withTranslation()((props) => (
  <SimpleEditPage
    {...props}
  >
    <SimpleEditPage.Tab
      key='details'
      name={props.t('Common.tabs.details')}
    >
      <Form.Input
        error={props.isError('name')}
        label={props.t('User.labels.name')}
        onChange={props.onTextInputChange.bind(this, 'name')}
        required={props.isRequired('name')}
        value={props.item.name || ''}
      />
      <Form.Input
        error={props.isError('email')}
        label={props.t('User.labels.email')}
        onChange={props.onTextInputChange.bind(this, 'email')}
        required={props.isRequired('email')}
        value={props.item.email || ''}
      />
      <Form.Input
        error={props.isError('saml_id')}
        label={props.t('User.labels.samlId')}
        onChange={props.onTextInputChange.bind(this, 'saml_id')}
        required={props.isRequired('saml_id')}
        value={props.item.saml_id || ''}
      />
      <Message
        content={props.t('User.password.content')}
        header={props.t('User.password.header')}
      />
      <Form.Input
        error={props.isError('password')}
        disabled={props.item.customer}
        label={props.t('User.labels.password')}
        onChange={props.onTextInputChange.bind(this, 'password')}
        required={props.isRequired('password')}
        type='password'
        value={props.item.password || ''}
      />
      <Form.Input
        error={props.isError('password_confirmation')}
        disabled={props.item.customer}
        label={props.t('User.labels.passwordConfirmation')}
        onChange={props.onTextInputChange.bind(this, 'password_confirmation')}
        required={props.isRequired('password_confirmation')}
        type='password'
        value={props.item.password_confirmation || ''}
      />
      <Form.Checkbox
        checked={props.item.admin_access}
        error={props.isError('admin_access')}
        label={props.t('User.labels.adminAccess')}
        onChange={props.onCheckboxInputChange.bind(this, 'admin_access')}
      />
    </SimpleEditPage.Tab>
  </SimpleEditPage>
));

const User: ComponentType<any> = withEditPage(UserForm, {
  id: 'userId',
  onInitialize: (id) => UsersService.fetchOne(id).then(({ data }) => data.user),
  onSave: (user) => UsersService.save(user).then(({ data }) => data.user),
  required: ['name', 'email']
});

export default User;
