// @flow

import { BaseTransform } from '@performant-software/shared-components';

/**
 * Class responsible for transforming user objects.
 */
class User extends BaseTransform {
  /**
   * Returns the array of users payload keys.
   *
   * @returns {*[]}
   */
  getPayloadKeys(): Array<string> {
    return [
      'name',
      'email',
      'password',
      'password_confirmation',
      'admin_access',
      'saml_id'
    ];
  }

  /**
   * Returns the user parameter name.
   *
   * @returns {string}
   */
  getParameterName(): string {
    return 'user';
  }
}

const UserTransform: User = new User();
export default UserTransform;
