// @flow

import { BaseTransform } from '@performant-software/shared-components';
import type { Award as AwardType } from '@udcsl/shared';
import Names from './Names';

/**
 * Class responsible for transforming award objects.
 */
class Award extends BaseTransform {
  /**
   * Returns the award parameter name.
   *
   * @returns {string}
   */
  getParameterName(): string {
    return 'award';
  }

  /**
   * Returns the award payload keys.
   *
   * @returns {string[]}
   */
  getPayloadKeys(): Array<string> {
    return [
      'name',
      'description'
    ];
  }

  /**
   * Returns the passed award as a dropdown option.
   *
   * @param award
   *
   * @returns {{text, value, key}}
   */
  toDropdown(award: AwardType): any {
    return {
      key: award.id,
      value: award.id,
      text: award.name
    };
  }

  /**
   * Returns the passed award formatted for PUT/POST requests.
   *
   * @param award
   *
   * @returns {*}
   */
  toPayload(award: AwardType): any {
    return super.toPayload(award, {
      ...Names.toPayload(award)
    });
  }
}

const AwardTransform: Award = new Award();
export default AwardTransform;
