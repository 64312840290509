// @flow

import { Reference as ReferenceUtils } from '@performant-software/controlled-vocabulary';
import { EmbeddedList } from '@performant-software/semantic-components';
import type { EditContainerProps } from '@performant-software/shared-components/types';
import React, { type ComponentType } from 'react';
import { useTranslation } from 'react-i18next';
import Organization from '../transforms/Organization';
import OrganizationModal from '../components/OrganizationModal';
import OrganizationsService from '../services/Organizations';
import ParticipationModal from '../components/ParticipationModal';
import PeopleService from '../services/People';
import PersonForm from '../components/PersonForm';
import SimpleEditPage from '../components/SimpleEditPage';
import withEditPage from '../hooks/EditPage';

const Form = (props: EditContainerProps) => {
  const { t } = useTranslation();

  return (
    <SimpleEditPage
      {...props}
    >
      <SimpleEditPage.Tab
        key='details'
        name={t('Common.tabs.details')}
      >
        <PersonForm
          {...props}
        />
      </SimpleEditPage.Tab>
      <SimpleEditPage.Tab
        key='organizations'
        name={t('Person.tabs.organizations')}
      >
        <EmbeddedList
          actions={[{
            name: 'edit'
          }, {
            name: 'delete'
          }]}
          columns={[{
            name: 'name',
            label: t('Person.organizations.columns.name'),
            resolve: (po) => po.participateable?.name,
            sortable: true
          }, {
            name: 'organization_type',
            label: t('Person.organizations.columns.type'),
            resolve: (po) => po.participateable?.organization_type_view,
            sortable: true
          }, {
            name: 'roles',
            label: t('Person.organizations.columns.roles'),
            resolve: (po) => ReferenceUtils.getViewValue(po.roles),
            sortable: true
          }]}
          items={props.item.organization_participations}
          modal={{
            component: ParticipationModal,
            props: {
              attribute: 'participateable_id',
              collectionName: 'organizations',
              label: t('Person.labels.organization'),
              modal: {
                component: OrganizationModal,
                onSave: (organization) => (
                  OrganizationsService
                    .save(organization)
                    .then(({ data }) => data.organization)
                ),
                props: {
                  required: ['name', 'organization_type']
                }
              },
              onSearch: (search) => OrganizationsService.fetchAll({ search }),
              renderItem: (organization) => organization.name,
              renderOption: (organization) => Organization.toDropdown(organization),
              required: ['participateable_id'],
              rolesReferenceTable: 'person_organization_roles',
              title: {
                add: t('Person.organizations.title.add'),
                edit: t('Person.organizations.title.edit')
              }
            }
          }}
          onDelete={props.onDeleteChildAssociation.bind(this, 'organization_participations')}
          onSave={props.onSaveChildAssociation.bind(this, 'organization_participations')}
        />
      </SimpleEditPage.Tab>
    </SimpleEditPage>
  );
};

const Person: ComponentType<any> = withEditPage(Form, {
  id: 'personId',
  onInitialize: (id) => (
    PeopleService
      .fetchOne(id)
      .then(({ data }) => data.person)
  ),
  onSave: (organization) => (
    PeopleService
      .save(organization)
      .then(({ data }) => data.person)
  ),
  required: ['first_name', 'last_name']
});

export default Person;
