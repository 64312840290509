// @flow

import {
  BooleanIcon,
  FilterOperators,
  FilterTypes,
  ListFilters,
  ListTable
} from '@performant-software/semantic-components';
import React, { type ComponentType } from 'react';
import { useTranslation } from 'react-i18next';
import { useLocation, useNavigate } from 'react-router-dom';
import Filters from '../utils/Filters';
import UsersService from '../services/Users';

const Users: ComponentType<any> = () => {
  const location = useLocation();
  const navigate = useNavigate();
  const { t } = useTranslation();

  return (
    <ListTable
      actions={[{
        name: 'edit',
        onClick: (item) => navigate(`${item.id}`)
      }, {
        name: 'delete'
      }]}
      addButton={{
        location: 'top',
        onClick: () => navigate('new')
      }}
      collectionName='users'
      columns={[{
        name: 'name',
        label: t('Users.columns.name'),
        sortable: true
      }, {
        name: 'email',
        label: t('Users.columns.email'),
        sortable: true
      }, {
        name: 'saml_id',
        label: t('Users.columns.samlId'),
        sortable: true
      }, {
        name: 'admin_access',
        label: t('Users.columns.admin'),
        render: (user) => (
          <BooleanIcon
            value={user.admin_access}
          />
        ),
        sortable: true
      }]}
      filters={{
        component: ListFilters,
        defaults: {
          filters: [{
            key: 'admin_access',
            operator: FilterOperators.equal,
            value: true
          }]
        },
        props: {
          filters: [{
            attributeName: 'admin_access',
            key: 'admin_access',
            label: t('Users.filters.admin'),
            type: FilterTypes.boolean
          }, {
            attributeName: 'saml_id',
            key: 'saml_id',
            label: t('Users.filters.samlId'),
            type: FilterTypes.string
          }]
        },
        showLabels: true
      }}
      onLoad={(params) => UsersService.search(Filters.prepareParams(params))}
      onDelete={(user) => UsersService.delete(user)}
      saved={location.state && location.state.saved}
      searchable
      session={{
        key: 'UsersList',
        storage: sessionStorage
      }}
    />
  );
};

export default Users;
