// @flow

import {
  FilterTypes,
  ItemList,
  LazyImage,
  ListFilters
} from '@performant-software/semantic-components';
import React, { type ComponentType, useCallback } from 'react';
import FeaturedLabel from '../components/FeaturedLabel';
import Filters from '../utils/Filters';
import ProjectsService from '../services/Projects';
import TextTruncate from '../components/TextTruncate';
import { useNavigate } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import VisibilityLabel from '../components/VisibilityLabel';
import { VisibilityOptions } from '../constants/Visibility';

const Projects: ComponentType<any> = () => {
  const navigate = useNavigate();
  const { t } = useTranslation();

  /**
   * Renders the labels for the passed project.
   *
   * @type {unknown}
   */
  const renderLabels = useCallback((project) => (
    <>
      <VisibilityLabel
        visibility={project.visibility}
      />
      { project.featured && (
        <FeaturedLabel
          value={project.featured}
        />
      )}
    </>
  ), []);

  return (
    <ItemList
      actions={[{
        name: 'edit',
        onClick: (item) => navigate(`${item.id}`)
      }, {
        name: 'delete'
      }]}
      addButton={{
        location: 'top',
        onClick: () => navigate('new')
      }}
      collectionName='projects'
      filters={{
        component: ListFilters,
        props: {
          filters: [{
            attributeName: 'featured',
            key: 'featured',
            label: t('Projects.filters.featured'),
            type: FilterTypes.integer
          }, {
            attributeName: 'visibility',
            key: 'visibility',
            label: t('Projects.filters.visibility'),
            options: VisibilityOptions,
            type: FilterTypes.select
          }]
        },
        showLabels: true
      }}
      onLoad={(params) => ProjectsService.search(Filters.prepareParams(params))}
      onDelete={(project) => ProjectsService.delete(project)}
      renderDescription={(project) => (
        <TextTruncate
          content={project.description}
          lines={2}
        />
      )}
      renderExtra={renderLabels}
      renderHeader={(project) => project.name}
      renderImage={(project) => (
        <LazyImage
          dimmable={false}
          preview={project.primary_media && project.primary_media.content_thumbnail_url}
        />
      )}
      renderMeta={(project) => project.place_name}
      session={{
        key: 'ProjectsList',
        storage: sessionStorage
      }}
      sort={[{
        key: 'name',
        value: 'names.name',
        text: t('Common.sort.name')
      }, {
        key: 'featured',
        value: 'projects.featured',
        text: t('Projects.sort.featured')
      }, {
        key: 'created_at',
        value: 'projects.created_at',
        text: t('Common.sort.created')
      }, {
        key: 'updated_at',
        value: 'projects.updated_at',
        text: t('Common.sort.updated')
      }]}
    />
  );
};

export default Projects;
