// @flow

import { ListTable } from '@performant-software/semantic-components';
import React, { type ComponentType } from 'react';
import { useTranslation } from 'react-i18next';
import { useLocation, useNavigate } from 'react-router-dom';
import OrganizationsService from '../services/Organizations';

const Organizations: ComponentType<any> = () => {
  const location = useLocation();
  const navigate = useNavigate();
  const { t } = useTranslation();

  return (
    <ListTable
      actions={[{
        name: 'edit',
        onClick: (item) => navigate(`${item.id}`)
      }, {
        name: 'delete'
      }]}
      addButton={{
        location: 'top',
        onClick: () => navigate('new')
      }}
      collectionName='organizations'
      columns={[{
        name: 'name',
        label: t('Organizations.columns.name'),
        sortable: true
      }, {
        name: 'organization_type',
        label: t('Organizations.columns.type'),
        resolve: (organization) => organization.organization_type_view,
        sortable: true
      }]}
      onLoad={(params) => OrganizationsService.fetchAll(params)}
      onDelete={(organization) => OrganizationsService.delete(organization)}
      saved={location.state && location.state.saved}
      session={{
        key: 'OrganizationsList',
        storage: sessionStorage
      }}
    />
  );
};

export default Organizations;
