// @flow

import React, { type ComponentType, useCallback, useMemo } from 'react';
import { ItemList, ItemViews, LazyImage } from '@performant-software/semantic-components';
import { useNavigate, useParams } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import ProjectAwardsService from '../services/ProjectAwards';
import { displayDate } from '@udcsl/shared';

const ProjectAwards: ComponentType<any> = () => {
  const navigate = useNavigate();
  const { awardId, projectId } = useParams();
  const { t } = useTranslation();

  /**
   * Default the view to a list for the awards tab and a grid for the projects tab.
   *
   * @type {*}
   */
  const defaultView = useMemo(() => {
    let view;

    if (awardId) {
      view = ItemViews.list;
    } else if (projectId) {
      view = ItemViews.grid;
    }

    return view;
  }, [awardId, projectId]);

  /**
   * Set the "name" column for sorting based on the awardId and projectId parameters.
   *
   * @type {*}
   */
  const nameColumn = useMemo(() => {
    let column;

    if (awardId) {
      column = 'project.name';
    } else {
      column = 'awards.name';
    }

    return column;
  }, [awardId, projectId]);

  /**
   * Renders the header content for the passed project award based on the awardId and projectId parameters.
   *
   * @type {function(*): *}
   */
  const renderHeader = useCallback((projectAward) => {
    let header;

    if (awardId) {
      header = projectAward.project?.name;
    } else if (projectId) {
      header = projectAward.award?.name;
    }

    return header;
  }, [awardId, projectId]);

  /**
   * Renders the image for the passed project award.
   *
   * @type {function(*): *}
   */
  const renderImage = useCallback((projectAward) => {
    let image;

    if (awardId) {
      image = (
        <LazyImage
          dimmable={false}
          preview={projectAward.project?.primary_media?.content_thumbnail_url}
        />
      );
    }

    return image;
  }, [awardId, projectId]);

  return (
    <ItemList
      actions={[{
        name: 'edit',
        onClick: (item) => navigate(`${item.id}`)
      }, {
        name: 'delete'
      }]}
      addButton={{
        location: 'top',
        onClick: () => navigate('new')
      }}
      collectionName='project_awards'
      defaultView={defaultView}
      onLoad={(params) => ProjectAwardsService.fetchAll({ ...params, award_id: awardId, project_id: projectId })}
      onDelete={(pa) => ProjectAwardsService.delete(pa)}
      renderDescription={(pa) => pa.description}
      renderHeader={renderHeader}
      renderImage={awardId && renderImage}
      renderMeta={(pa) => displayDate(pa.date, pa.date_display)}
      session={{
        key: 'ProjectAwardsList',
        storage: sessionStorage
      }}
      sort={[{
        key: 'date',
        value: 'project_awards.date',
        text: t('Common.sort.date')
      }, {
        key: 'name',
        value: nameColumn,
        text: t('Common.sort.name')
      }, {
        key: 'created_at',
        value: 'project_awards.created_at',
        text: t('Common.sort.created')
      }, {
        key: 'updated_at',
        value: 'project_awards.updated_at',
        text: t('Common.sort.updated')
      }]}
    />
  );
};

export default ProjectAwards;
