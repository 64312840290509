// @flow

import type { Nameable } from '@udcsl/shared';
import _ from 'underscore';
import i18n from '../i18n/i18n';

const ERRORS_NAME_REQUIRED = 'name_required';
const ERRORS_PRIMARY_TOO_FEW = 'too_few_primary';
const ERRORS_PRIMARY_TOO_MANY = 'too_many_primary';

type ValidateType = (item: Nameable) => any;

/**
 * Validates the related name records for the passed item.
 *
 * @param item
 *
 * @returns {{}}
 */
const validate: ValidateType = (item: Nameable) => {
  const errors = {};

  // At least one name is required
  if (_.isEmpty(item?.names)) {
    _.extend(errors, { [ERRORS_NAME_REQUIRED]: i18n.t('Names.errors.required') });
  }

  const primaryNames = _.where(item?.names, { primary: true });

  // At least one primary name is required
  if (_.isEmpty(primaryNames) && !_.isEmpty(item?.names)) {
    _.extend(errors, { [ERRORS_PRIMARY_TOO_FEW]: i18n.t('Names.errors.primary.tooFew') });
  }

  // Only one primary name is allowed
  if (primaryNames.length > 1) {
    _.extend(errors, { [ERRORS_PRIMARY_TOO_MANY]: i18n.t('Names.errors.primary.tooMany') });
  }

  return errors;
};

export default {
  validate
};
