import React from 'react';
import styles from './TextTruncate.module.css';

type Props = {
  content: string,
  lines: number
};

const TextTruncate = ({ content, lines = 2 }: Props) => (
  <div
    className={styles.textTruncate}
    style={{
      WebkitLineClamp: lines
    }}
  >
    { content }
  </div>
);

export default TextTruncate;
