// @flow

import { ListTable } from '@performant-software/semantic-components';
import React, { type ComponentType } from 'react';
import { useTranslation } from 'react-i18next';
import { useLocation, useNavigate } from 'react-router-dom';
import AwardsService from '../services/Awards';

const Events: ComponentType<any> = () => {
  const location = useLocation();
  const navigate = useNavigate();
  const { t } = useTranslation();

  return (
    <ListTable
      actions={[{
        name: 'edit',
        onClick: (item) => navigate(`${item.id}`)
      }, {
        name: 'delete'
      }]}
      addButton={{
        location: 'top',
        onClick: () => navigate('new')
      }}
      collectionName='awards'
      columns={[{
        name: 'names.name',
        label: t('Awards.columns.name'),
        resolve: (name) => name.name,
        sortable: true
      }]}
      onLoad={(params) => AwardsService.fetchAll(params)}
      onDelete={(award) => AwardsService.delete(award)}
      saved={location.state && location.state.saved}
      session={{
        key: 'AwardsList',
        storage: sessionStorage
      }}
    />
  );
};

export default Events;
