// @flow

import { Reference as ReferenceUtils } from '@performant-software/controlled-vocabulary';
import { AssociatedDropdown, DatePicker, EmbeddedList } from '@performant-software/semantic-components';
import type { EditContainerProps } from '@performant-software/shared-components/types';
import { Date as DateUtils } from '@performant-software/shared-components';
import type { ProjectAward as ProjectAwardType } from '@udcsl/shared';
import React, { type ComponentType, useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { useParams } from 'react-router-dom';
import { Form } from 'semantic-ui-react';
import Award from '../transforms/Award';
import AwardModal from '../components/AwardModal';
import AwardsService from '../services/Awards';
import { DateDisplayOptions } from '../constants/DateDisplay';
import Names from '../utils/Names';
import ParticipationModal from '../components/ParticipationModal';
import PersonModal from '../components/PersonModal';
import PeopleService from '../services/People';
import Person from '../transforms/Person';
import Project from '../transforms/Project';
import ProjectAwardsService from '../services/ProjectAwards';
import ProjectsService from '../services/Projects';
import SimpleEditPage from '../components/SimpleEditPage';
import withEditPage from '../hooks/EditPage';

type Props = EditContainerProps & {
  item: ProjectAwardType
};

const ProjectAwardForm = (props: Props) => {
  const params = useParams();
  const { t } = useTranslation();

  /*
   * For a new record, set the foreign key ID based on the route parameters.
   */
  useEffect(() => {
    if (!props.item.id) {
      if (params.awardId) {
        props.onSetState({ award_id: params.awardId });
      } else if (params.projectId) {
        props.onSetState({ project_id: params.projectId });
      }
    }
  }, []);

  return (
    <SimpleEditPage
      {...props}
    >
      <SimpleEditPage.Tab
        key='details'
        name={t('Common.tabs.details')}
      >
        { params.awardId && (
          <Form.Input
            error={props.isError('project_id')}
            label={t('ProjectAward.labels.project')}
            required={props.isRequired('project_id')}
          >
            <AssociatedDropdown
              collectionName='projects'
              onSearch={(search) => ProjectsService.fetchAll({ search })}
              onSelection={props.onAssociationInputChange.bind(this, 'project_id', 'project')}
              renderOption={(project) => Project.toDropdown(project)}
              searchQuery={props.item.project?.name}
              value={props.item.project_id}
            />
          </Form.Input>
        )}
        { params.projectId && (
          <Form.Input
            error={props.isError('award_id')}
            label={t('ProjectAward.labels.award')}
            required={props.isRequired('award_id')}
          >
            <AssociatedDropdown
              collectionName='awards'
              modal={{
                component: AwardModal,
                props: {
                  validate: (award) => Names.validate(award)
                },
                onSave: (award) => (
                  AwardsService
                    .save(award)
                    .then(({ data }) => data.award)
                )
              }}
              onSearch={(search) => AwardsService.fetchAll({ search })}
              onSelection={props.onAssociationInputChange.bind(this, 'award_id', 'award')}
              renderOption={(award) => Award.toDropdown(award)}
              searchQuery={props.item.award?.name}
              value={props.item.award_id}
            />
          </Form.Input>
        )}
        <Form.Input
          error={props.isError('date')}
          label={t('ProjectAward.labels.date')}
          required={props.isRequired('date')}
        >
          <DatePicker
            onChange={(date) => props.onSetState({ date: date?.toString() })}
            value={DateUtils.withoutTime(props.item.date)}
          />
        </Form.Input>
        <Form.Dropdown
          error={props.isError('date_display')}
          label={t('ProjectAward.labels.dateDisplay')}
          onChange={props.onTextInputChange.bind(this, 'date_display')}
          options={DateDisplayOptions}
          required={props.isRequired('date_display')}
          selection
          selectOnBlur={false}
          value={props.item.date_display}
        />
        <Form.TextArea
          error={props.isError('description')}
          label={t('ProjectAward.labels.description')}
          onChange={props.onTextInputChange.bind(this, 'description')}
          required={props.isRequired('description')}
          value={props.item.description || ''}
        />
        <Form.Input
          error={props.isError('website_url')}
          label={t('ProjectAward.labels.websiteUrl')}
          onChange={props.onTextInputChange.bind(this, 'website_url')}
          required={props.isRequired('website_url')}
          value={props.item.website_url || ''}
        />
      </SimpleEditPage.Tab>
      <SimpleEditPage.Tab
        key='jury'
        name={t('ProjectAward.tabs.jury')}
      >
        <EmbeddedList
          actions={[{
            name: 'edit'
          }, {
            name: 'delete'
          }]}
          columns={[{
            name: 'first_name',
            label: t('ProjectAward.jury.columns.first_name'),
            resolve: (p) => p.participant?.first_name,
            sortable: true
          }, {
            name: 'last_name',
            label: t('ProjectAward.jury.columns.last_name'),
            resolve: (p) => p.participant?.last_name,
            sortable: true
          }, {
            name: 'type',
            label: t('ProjectAward.jury.columns.roles'),
            resolve: (p) => ReferenceUtils.getViewValue(p.roles),
            sortable: true
          }]}
          items={props.item.person_participants}
          modal={{
            component: ParticipationModal,
            props: {
              attribute: 'participant_id',
              collectionName: 'people',
              label: t('ProjectAward.labels.person'),
              modal: {
                component: PersonModal,
                onSave: (person) => (
                  PeopleService
                    .save(person)
                    .then(({ data }) => data.person)
                ),
                props: {
                  required: ['first_name', 'last_name']
                }
              },
              onSearch: (search) => PeopleService.fetchAll({ search }),
              renderItem: (person) => person.name,
              renderOption: (person) => Person.toDropdown(person),
              required: ['participant_id', 'roles'],
              rolesReferenceTable: 'project_award_jury_roles',
              title: {
                add: t('ProjectAward.jury.title.add'),
                edit: t('ProjectAward.jury.title.edit')
              }
            }
          }}
          onDelete={props.onDeleteChildAssociation.bind(this, 'person_participants')}
          onSave={props.onSaveChildAssociation.bind(this, 'person_participants')}
        />
      </SimpleEditPage.Tab>
    </SimpleEditPage>
  );
};

const ProjectAward: ComponentType<any> = withEditPage(ProjectAwardForm, {
  id: 'projectAwardId',
  onInitialize: (id) => (
    ProjectAwardsService
      .fetchOne(id)
      .then(({ data }) => data.project_award)
  ),
  onSave: (pa) => (
    ProjectAwardsService
      .save(pa)
      .then(({ data }) => data.project_award)
  ),
  required: [
    'award_id',
    'project_id'
  ]
});

export default ProjectAward;
