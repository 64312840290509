// @flow

import { ReferenceCodeFormDropdown } from '@performant-software/controlled-vocabulary';
import React, { type ComponentType } from 'react';
import { withTranslation } from 'react-i18next';
import { Form } from 'semantic-ui-react';

const PersonForm: ComponentType<any> = withTranslation()((props) => (
  <>
    <Form.Input
      autoFocus
      error={props.isError('first_name')}
      label={props.t('Person.labels.first_name')}
      onChange={props.onTextInputChange.bind(this, 'first_name')}
      required={props.isRequired('first_name')}
      value={props.item.first_name || ''}
    />
    <Form.Input
      autoFocus
      error={props.isError('last_name')}
      label={props.t('Person.labels.last_name')}
      onChange={props.onTextInputChange.bind(this, 'last_name')}
      required={props.isRequired('last_name')}
      value={props.item.last_name || ''}
    />
    <Form.TextArea
      error={props.isError('biography')}
      label={props.t('Person.labels.biography')}
      onChange={props.onTextInputChange.bind(this, 'biography')}
      required={props.isRequired('biography')}
      value={props.item.biography || ''}
    />
    <ReferenceCodeFormDropdown
      error={props.isError('profession')}
      label={props.t('Person.labels.profession')}
      required={props.isRequired('profession')}
      onChange={(profession) => props.onSetState({ profession })}
      referenceTable='profession'
      value={props.item.profession}
    />
  </>
));

export default PersonForm;
