// @flow

import { ReferenceCodeFormDropdown } from '@performant-software/controlled-vocabulary';
import {
  DatePicker,
  ItemCollection,
  LazyImage,
  Selectize
} from '@performant-software/semantic-components';
import { Date as DateUtils } from '@performant-software/shared-components';
import type { EditContainerProps } from '@performant-software/shared-components/types';
import type { EventMediaContent } from '@udcsl/shared';
import React, { type ComponentType, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import uuid from 'react-uuid';
import { Form, Item } from 'semantic-ui-react';
import _ from 'underscore';
import EventsService from '../services/Events';
import MediaContentsService from '../services/MediaContents';
import SimpleEditPage from '../components/SimpleEditPage';
import useEventable from '../hooks/Eventable';
import withEditPage from '../hooks/EditPage';
import { DateDisplayOptions } from '../constants/DateDisplay';

const EventForm = (props: EditContainerProps) => {
  const [selectize, setSelectize] = useState(false);

  const { eventableId, eventableType } = useEventable();
  const { t } = useTranslation();

  /**
   * Initialize the eventable ID and eventable type on the state.
   */
  useEffect(() => {
    props.onSetState({
      eventable_id: eventableId,
      eventable_type: eventableType
    });
  }, []);

  return (
    <SimpleEditPage
      {...props}
    >
      <SimpleEditPage.Tab
        key='details'
        name={t('Common.tabs.details')}
      >
        <Form.Input
          error={props.isError('label')}
          label={t('Event.labels.label')}
          onChange={props.onTextInputChange.bind(this, 'label')}
          required={props.isRequired('label')}
          value={props.item.label || ''}
        />
        <Form.TextArea
          error={props.isError('description')}
          label={t('Event.labels.description')}
          onChange={props.onTextInputChange.bind(this, 'description')}
          required={props.isRequired('description')}
          value={props.item.description || ''}
        />
        <ReferenceCodeFormDropdown
          error={props.isError('event_type')}
          label={t('Event.labels.type')}
          required={props.isRequired('event_type')}
          onChange={(eventType) => props.onSetState({ event_type: eventType })}
          referenceTable='event_types'
          value={props.item.event_type}
        />
        <Form.Input
          error={props.isError('start_date')}
          label={t('Event.labels.startDate')}
          required={props.isRequired('start_date')}
        >
          <DatePicker
            onChange={(date) => props.onSetState({ start_date: date?.toString() })}
            value={DateUtils.withoutTime(props.item.start_date)}
          />
        </Form.Input>
        <Form.Dropdown
          error={props.isError('start_date_display')}
          label={t('Event.labels.startDateDisplay')}
          onChange={props.onTextInputChange.bind(this, 'start_date_display')}
          options={DateDisplayOptions}
          required={props.isRequired('start_date_display')}
          selection
          selectOnBlur={false}
          value={props.item.start_date_display}
        />
        <Form.Input
          error={props.isError('end_date')}
          label={t('Event.labels.endDate')}
          required={props.isRequired('end_date')}
        >
          <DatePicker
            onChange={(date) => props.onSetState({ end_date: date?.toString() })}
            value={DateUtils.withoutTime(props.item.end_date)}
          />
        </Form.Input>
        <Form.Dropdown
          error={props.isError('end_date_display')}
          label={t('Event.labels.endDateDisplay')}
          onChange={props.onTextInputChange.bind(this, 'end_date_display')}
          options={DateDisplayOptions}
          required={props.isRequired('end_date_display')}
          selection
          selectOnBlur={false}
          value={props.item.end_date_display}
        />
        { eventableType === 'Project' && (
          <Form.Checkbox
            checked={props.item.include_dates}
            error={props.isError('include_dates')}
            label={t('Event.labels.includeDates')}
            onChange={props.onCheckboxInputChange.bind(this, 'include_dates')}
            required={props.isRequired('include_dates')}
          />
        )}
      </SimpleEditPage.Tab>
      { eventableType === 'Project' && (
        <SimpleEditPage.Tab
          key='media'
          name={t('Event.tabs.media')}
        >
          <ItemCollection
            actions={[{
              name: 'delete'
            }]}
            addButton={{
              location: 'top',
              onClick: () => setSelectize(true)
            }}
            items={props.item.event_media_contents}
            onDelete={props.onDeleteChildAssociation.bind(this, 'event_media_contents')}
            renderHeader={(emc) => emc.media_content.label}
            renderImage={(emc) => (
              <LazyImage
                dimmable={false}
                preview={emc.media_content.content_thumbnail_url}
                size='small'
              />
            )}
            renderMeta={() => ''}
          />
          { selectize && (
            <Selectize
              collectionName='media_contents'
              onClose={() => setSelectize(false)}
              onLoad={(params) => MediaContentsService.fetchAll({ ...params, project_id: eventableId })}
              onSave={(items) => {
                const find = (item: EventMediaContent) => _.findWhere(props.item.event_media_content, {
                  media_content_id: item.id
                });

                const create = (item: EventMediaContent) => ({
                  media_content_id: item.id,
                  media_content: item,
                  uid: uuid()
                });

                props.onMultiAddChildAssociations(
                  'event_media_contents',
                  _.map(items, (item) => find(item) || create(item))
                );

                setSelectize(false);
              }}
              renderItem={(item) => (
                <Item.Group>
                  <Item>
                    <Item.Image>
                      <LazyImage
                        dimmable={false}
                        preview={item.content_thumbnail_url}
                        size='small'
                      />
                    </Item.Image>
                    <Item.Content>
                      <Item.Header
                        content={item.label}
                      />
                    </Item.Content>
                  </Item>
                </Item.Group>
              )}
              selectedItems={_.map(props.item.event_media_contents, (emc) => emc.media_content)}
              title={t('Event.media.title')}
            />
          )}
        </SimpleEditPage.Tab>
      )}
    </SimpleEditPage>
  );
};

const Event: ComponentType<any> = withEditPage(EventForm, {
  id: 'eventId',
  onInitialize: (id) => (
    EventsService
      .fetchOne(id)
      .then(({ data }) => data.event)
  ),
  onSave: (event) => (
    EventsService
      .save(event)
      .then(({ data }) => data.event)
  ),
  required: ['label']
});

export default Event;
