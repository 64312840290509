// @flow

import { ReferenceCodeFormDropdown } from '@performant-software/controlled-vocabulary';
import { BibliographyList, KeyValuePairs, LazyIIIF } from '@performant-software/semantic-components';
import { IIIF as IIIFUtils } from '@performant-software/shared-components';
import { type EditContainerProps } from '@performant-software/shared-components/types';
import type { MediaContent } from '@udcsl/shared';
import React, { type ComponentType, useEffect, useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import { useParams } from 'react-router-dom';
import { Form } from 'semantic-ui-react';
import MediaContentsService from '../services/MediaContents';
import SimpleEditPage from '../components/SimpleEditPage';
import { Visibility, VisibilityOptions } from '../constants/Visibility';
import withEditPage from '../hooks/EditPage';

type Props = EditContainerProps & {
  item: MediaContent
};

const MediaContentForm = (props: Props) => {
  const { projectId } = useParams();
  const { t } = useTranslation();

  /**
   * Sets the manifest URL.
   *
   * @type {string|string|*}
   */
  const manifest = useMemo(() => IIIFUtils.createManifestURL(props.item.manifest), [props.item.manifest]);

  /**
   * Set the project ID based on the URL parameters.
   */
  useEffect(() => {
    props.onSetState({ project_id: projectId });
  }, []);

  /**
   * Default the visibility to "private".
   */
  useEffect(() => {
    if (!props.item.id) {
      props.onSetState({ visibility: Visibility.private });
    }
  }, []);

  return (
    <SimpleEditPage
      {...props}
    >
      <SimpleEditPage.Tab
        key='details'
        name={t('Common.tabs.details')}
      >
        <Form.Input
          label={t('MediaContent.labels.content')}
        >
          <LazyIIIF
            color='teal'
            contentType={props.item.content_type}
            downloadUrl={props.item.content_download_url}
            manifest={manifest}
            onUpload={(file) => props.onSetState({
              label: file.name,
              content: file
            })}
            preview={props.item.content_preview_url}
            src={props.item.content_url}
          />
        </Form.Input>
        <Form.Input
          error={props.isError('label')}
          label={t('MediaContent.labels.label')}
          onChange={props.onTextInputChange.bind(this, 'label')}
          required={props.isRequired('label')}
          value={props.item.label}
        />
        <Form.TextArea
          error={props.isError('copyright')}
          label={t('MediaContent.labels.copyright')}
          onChange={props.onTextInputChange.bind(this, 'copyright')}
          required={props.isRequired('copyright')}
          value={props.item.copyright}
        />
        <ReferenceCodeFormDropdown
          error={props.isError('file_type')}
          label={t('MediaContent.labels.fileType')}
          required={props.isRequired('file_type')}
          onChange={(fileType) => props.onSetState({ file_type: fileType })}
          referenceTable='file_type'
          value={props.item.file_type}
        />
        <Form.Dropdown
          error={props.isError('visibility')}
          label={t('MediaContent.labels.visibility')}
          onChange={props.onTextInputChange.bind(this, 'visibility')}
          options={VisibilityOptions}
          required={props.isRequired('visibility')}
          selection
          selectOnBlur={false}
          value={props.item.visibility}
        />
        <Form.Input
          error={props.isError('caption')}
          label={t('MediaContent.labels.caption')}
          onChange={props.onTextInputChange.bind(this, 'caption')}
          required={props.isRequired('caption')}
          value={props.item.caption}
        />
        <Form.TextArea
          error={props.isError('description')}
          label={t('MediaContent.labels.description')}
          onChange={props.onTextInputChange.bind(this, 'description')}
          required={props.isRequired('description')}
          value={props.item.description}
        />
        <Form.TextArea
          error={props.isError('timeframe')}
          label={t('MediaContent.labels.timeframe')}
          required={props.isRequired('timeframe')}
          onChange={props.onTextInputChange.bind(this, 'timeframe')}
          value={props.item.timeframe}
        />
        <ReferenceCodeFormDropdown
          error={props.isError('keywords')}
          label={t('MediaContent.labels.keywords')}
          multiple
          required={props.isRequired('keywords')}
          onChange={(keywords) => props.onSetState({ keywords })}
          referenceTable='media_content_keywords'
          value={props.item.keywords || []}
        />
        <Form.Checkbox
          checked={props.item.primary}
          error={props.isError('primary')}
          label={t('MediaContent.labels.primary')}
          onChange={props.onCheckboxInputChange.bind(this, 'primary')}
        />
      </SimpleEditPage.Tab>
      <SimpleEditPage.Tab
        key='identifiers'
        name={t('MediaContent.tabs.identifiers')}
      >
        <KeyValuePairs
          items={JSON.parse(props.item.external_identifiers || '[]')}
          onChange={(items) => props.onSetState({ external_identifiers: JSON.stringify(items) })}
        />
      </SimpleEditPage.Tab>
      <SimpleEditPage.Tab
        key='bibliography'
        name={t('Common.tabs.bibliography')}
      >
        <BibliographyList
          items={props.item.citations}
          onDelete={props.onDeleteChildAssociation.bind(this, 'citations')}
          onSave={props.onSaveChildAssociation.bind(this, 'citations')}
          translateUrl={process.env.REACT_APP_TRANSLATE_URL}
        />
      </SimpleEditPage.Tab>
    </SimpleEditPage>
  );
};

const Project: ComponentType<any> = withEditPage(MediaContentForm, {
  id: 'mediaContentId',
  onInitialize: (id) => (
    MediaContentsService
      .fetchOne(id)
      .then(({ data }) => data.media_content)
  ),
  onSave: (project) => (
    MediaContentsService
      .save(project)
      .then(({ data }) => data.media_content)
  ),
  defaults: {
    visibility: Visibility.private
  },
  required: [
    'copyright',
    'file_type',
    'label',
    'visibility'
  ]
});

export default Project;
