// @flow

import cx from 'classnames';
import React, { type AbstractComponent } from 'react';
import { FaChevronLeft, FaChevronRight } from 'react-icons/fa';
import { Button } from 'semantic-ui-react';
import styles from './NumberPicker.module.css';

type Props = {
  onChange: (value: number) => void,
  value: number
};

const NumberPicker: AbstractComponent<any> = (props: Props) => (
  <div
    className={styles.numberPicker}
  >
    <Button
      basic
      className={cx(styles.ui, styles.basic, styles.button)}
      compact
      disabled={props.value === 1}
      onClick={() => props.onChange(props.value - 1)}
    >
      <FaChevronLeft />
    </Button>
    { props.value }
    <Button
      basic
      className={cx(styles.ui, styles.basic, styles.button)}
      compact
      onClick={() => props.onChange(props.value + 1)}
    >
      <FaChevronRight />
    </Button>
  </div>
);

export default NumberPicker;
