// @flow

import type { Translateable } from '@udcsl/shared';
import cx from 'classnames';
import React, { useCallback, type ComponentType } from 'react';
import { BsFillPeopleFill } from 'react-icons/bs';
import { FaAward, FaCog, FaFolderOpen } from 'react-icons/fa';
import { RiOrganizationChart } from 'react-icons/ri';
import { withTranslation } from 'react-i18next';
import { useMatch, useNavigate, useParams } from 'react-router-dom';
import { Icon, Menu, Popup } from 'semantic-ui-react';
import AuthenticationService from '../services/Authentication';
import Logo from './Logo';
import MenuLink from './MenuLink';
import styles from './Sidebar.module.css';

type Props = Translateable & {
  context: {
    current: ?HTMLDivElement
  }
};

const Sidebar: ComponentType<any> = withTranslation()((props: Props) => {
  const isSettings = useMatch({ path: '/settings*', end: true });
  const navigate = useNavigate();
  const params = useParams();

  /**
   * Logs the user out and navigates to the index page.
   *
   * @type {function(): Promise<R>|Promise<R|unknown>|Promise<*>|*}
   */
  const onLogout = useCallback(() => AuthenticationService.logout().then(() => navigate('/')), []);

  return (
    <div
      className={styles.adminSidebar}
      ref={props.context}
    >
      <Menu
        className={cx(styles.ui, styles.vertical, styles.icon, styles.menu)}
        inverted
        icon='labeled'
        vertical
      >
        <Menu.Item
          header
        >
          <Logo />
        </Menu.Item>
        <Popup
          content={props.t('Sidebar.labels.projects')}
          mouseEnterDelay={1000}
          position='right center'
          trigger={(
            <MenuLink
              className={styles.item}
              parent
              to='/projects'
            >
              <FaFolderOpen
                size='2em'
              />
              { params.projectId && (
                <Menu.Menu>
                  <MenuLink
                    content={props.t('Sidebar.labels.details')}
                    to={`/projects/${params.projectId}`}
                  />
                  <MenuLink
                    content={props.t('Sidebar.labels.awards')}
                    parent
                    to={`/projects/${params.projectId}/project_awards`}
                  />
                  <MenuLink
                    content={props.t('Sidebar.labels.events')}
                    parent
                    to={`/projects/${params.projectId}/events`}
                  />
                  <MenuLink
                    content={props.t('Sidebar.labels.media')}
                    parent
                    to={`/projects/${params.projectId}/media_contents`}
                  />
                </Menu.Menu>
              )}
            </MenuLink>
          )}
        />
        <Popup
          content={props.t('Sidebar.labels.organizations')}
          mouseEnterDelay={1000}
          position='right center'
          trigger={(
            <MenuLink
              className={styles.item}
              parent
              to='/organizations'
            >
              <RiOrganizationChart
                size='2em'
              />
              { params.organizationId && (
                <Menu.Menu>
                  <MenuLink
                    content={props.t('Sidebar.labels.details')}
                    to={`/organizations/${params.organizationId}`}
                  />
                </Menu.Menu>
              )}
            </MenuLink>
          )}
        />
        <Popup
          content={props.t('Sidebar.labels.people')}
          mouseEnterDelay={1000}
          position='right center'
          trigger={(
            <MenuLink
              className={styles.item}
              parent
              to='/people'
            >
              <BsFillPeopleFill
                size='2em'
              />
              { params.personId && (
                <Menu.Menu>
                  <MenuLink
                    content={props.t('Sidebar.labels.details')}
                    to={`/people/${params.personId}`}
                  />
                </Menu.Menu>
              )}
            </MenuLink>
          )}
        />
        <Popup
          content={props.t('Sidebar.labels.awards')}
          mouseEnterDelay={1000}
          position='right center'
          trigger={(
            <MenuLink
              className={styles.item}
              parent
              to='/awards'
            >
              <FaAward
                size='2em'
              />
              { params.awardId && (
                <Menu.Menu>
                  <MenuLink
                    content={props.t('Sidebar.labels.details')}
                    to={`/awards/${params.awardId}`}
                  />
                  <MenuLink
                    content={props.t('Sidebar.labels.projects')}
                    parent
                    to={`/awards/${params.awardId}/project_awards`}
                  />
                </Menu.Menu>
              )}
            </MenuLink>
          )}
        />
        <Popup
          content={props.t('Sidebar.labels.settings')}
          mouseEnterDelay={1000}
          position='right center'
          trigger={(
            <MenuLink
              className={styles.item}
              parent
              to='/settings'
            >
              <FaCog
                size='2em'
              />
              { isSettings && (
                <Menu.Menu>
                  <MenuLink
                    content={props.t('Sidebar.labels.settings')}
                    to='/settings'
                  />
                  <MenuLink
                    content={props.t('Sidebar.labels.users')}
                    parent
                    to='/settings/users'
                  />
                  <MenuLink
                    content={props.t('Sidebar.labels.reference')}
                    to='/settings/reference_tables'
                  />
                </Menu.Menu>
              )}
            </MenuLink>
          )}
        />
        <Popup
          content={props.t('Sidebar.labels.logout')}
          mouseEnterDelay={1000}
          position='right center'
          trigger={(
            <Menu.Item
              className={styles.item}
              onClick={onLogout}
            >
              <Icon
                flipped='horizontally'
                name='log out'
                size='big'
              />
            </Menu.Item>
          )}
        />
      </Menu>
    </div>
  );
});

export default Sidebar;
