// @flow

import { BooleanIcon, EmbeddedList } from '@performant-software/semantic-components';
import type { Nameable, Translateable } from '@udcsl/shared';
import React, { type ComponentType } from 'react';
import { withTranslation } from 'react-i18next';
import _ from 'underscore';
import NameModal from './NameModal';
import styles from './NamesList.module.css';

type Props = Translateable & {
  items: Array<Nameable>,
  onDelete: (key: string, item: Nameable) => void,
  onSave: (key: string, item: Nameable) => void
};

const NamesList: ComponentType<any> = withTranslation()((props: Props) => (
  <EmbeddedList
    actions={[{
      name: 'edit'
    }, {
      name: 'delete'
    }]}
    className={styles.nameList}
    columns={[{
      name: 'name',
      label: props.t('NamesList.columns.name'),
      sortable: true
    }, {
      name: 'primary',
      label: props.t('NamesList.columns.primary'),
      render: (name) => (
        <BooleanIcon
          value={name.primary}
        />
      ),
      sortable: true
    }]}
    defaultSort='primary'
    defaultSortDirection='descending'
    items={props.items}
    modal={{
      component: NameModal,
      props: {
        defaults: {
          primary: _.isEmpty(props.items)
        },
        required: ['name']
      }
    }}
    onDelete={props.onDelete.bind(this, 'names')}
    onSave={props.onSave.bind(this, 'names')}
  />
));

export default NamesList;
