// @flow

import _ from 'underscore';
import i18n from '../i18n/i18n';

const DateDisplay = {
  day: 'day',
  month: 'month',
  year: 'year'
};

const DateDisplayOptions: any = _.map(_.values(DateDisplay), (v) => ({
  key: v,
  value: v,
  text: i18n.t(`Common.constants.dateDisplay.${v}`)
}));

export {
  DateDisplay,
  DateDisplayOptions
};
