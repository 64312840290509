// @flow

import cx from 'classnames';
import React, {
  useCallback,
  useEffect,
  useState,
  type ComponentType
} from 'react';
import { Navigate, useSearchParams } from 'react-router-dom';
import {
  Button,
  Divider,
  Form,
  Grid,
  Header,
  Icon,
  Image,
  Message,
  Segment
} from 'semantic-ui-react';
import AuthenticationService from '../services/Authentication';
import styles from './Login.module.css';
import { useTranslation } from 'react-i18next';

const KEY_TOKEN = 'token';

const ErrorTypes = {
  login: 'login',
  saml: 'saml'
};

const Login: ComponentType<any> = () => {
  const [disabled, setDisabled] = useState(false);
  const [email, setEmail] = useState();
  const [error, setError] = useState(null);
  const [password, setPassword] = useState();

  const [searchParams] = useSearchParams();
  const token = searchParams.get(KEY_TOKEN);

  const { t } = useTranslation();

  /**
   * Attempts to authenticate then navigates to the admin page.
   *
   * @type {(function(): void)|*}
   */
  const onLogin = useCallback(() => {
    setDisabled(true);

    AuthenticationService
      .login({ email, password })
      .catch(() => setError(ErrorTypes.login))
      .finally(() => setDisabled(false));
  }, [email, password]);

  /**
   * If a token is present on the request, log in the user via token.
   */
  useEffect(() => {
    if (token) {
      setDisabled(true);

      AuthenticationService
        .loginToken(token)
        .catch(() => setError(ErrorTypes.saml))
        .finally(() => setDisabled(false));
    }
  }, [token]);

  if (AuthenticationService.isAuthenticated()) {
    return <Navigate to='/projects' />;
  }

  return (
    <Grid
      className={cx(styles.ui, styles.grid, styles.login)}
      columns={2}
    >
      <Grid.Column
        className={cx(styles.title, styles.column)}
      >
        <Image
          className={cx(styles.ui, styles.image, styles.background)}
          src='/assets/boston.jpeg'
        />
        <div
          className={styles.overlay}
        />
        <Header
          as='h1'
          content={t('Login.title')}
          inverted
          subheader={t('Login.subtitle')}
        />
      </Grid.Column>
      <Grid.Column
        className={cx(styles.form, styles.column)}
      >
        <Segment
          as={Form}
          error={error}
          className={cx(styles.ui, styles.segment)}
        >
          { process.env.REACT_APP_SAML_LOGIN_URL && (
            <>
              <Button
                as='a'
                className={cx(styles.ssoButton, styles.ui, styles.button)}
                color='red'
                content={t('Login.buttons.loginHarvard')}
                href={`${process.env.REACT_APP_SAML_LOGIN_URL || ''}?callback_url=${window.location.href}`}
                icon='key'
                fluid
                labelPosition='left'
              />
              <Divider
                className={styles.divider}
                horizontal
              >
                { t('Login.labels.or') }
              </Divider>
            </>
          )}
          <Form.Input
            autoFocus
            className='form-field'
            disabled={disabled}
            icon={<Icon name='at' />}
            onChange={(e, { value }) => setEmail(value)}
            placeholder={t('Login.labels.email')}
          />
          <Form.Input
            className='form-field'
            disabled={disabled}
            icon={<Icon name='lock' />}
            onChange={(e, { value }) => setPassword(value)}
            placeholder={t('Login.labels.password')}
            type='password'
          />
          <Form.Button
            className={styles.loginButton}
            content={t('Login.buttons.login')}
            disabled={disabled}
            primary
            onClick={onLogin}
          />
        </Segment>
        { error && (
          <Message
            className={cx(styles.ui, styles.message)}
            compact
            content={error === ErrorTypes.login ? t('Login.errors.login') : t('Login.errors.saml')}
            error
            fluid
            onDismiss={() => setError(null)}
          />
        )}
      </Grid.Column>
    </Grid>
  );
};

export default Login;
