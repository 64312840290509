// @flow

import { ReferencesTransform } from '@performant-software/controlled-vocabulary';
import { BaseTransform } from '@performant-software/shared-components';
import type { Organization as OrganizationType } from '@udcsl/shared';
import Participations from './Participantions';

/**
 * Class responsible for transforming organization objects.
 */
class Organization extends BaseTransform {
  /**
   * Returns the array of organization payload keys.
   *
   * @returns {*[]}
   */
  getPayloadKeys(): Array<string> {
    return [
      'name',
      'description'
    ];
  }

  /**
   * Returns the organization parameter name.
   *
   * @returns {string}
   */
  getParameterName(): string {
    return 'organization';
  }

  /**
   * Returns the passed organization as a dropdown option.
   *
   * @param organization
   *
   * @returns {{text, value, key}}
   */
  toDropdown(organization: OrganizationType): any {
    return {
      key: organization.id,
      value: organization.id,
      text: organization.name
    };
  }

  /**
   * Returns the passed organization as a payload for POST/PUT requests.
   *
   * @param organization
   *
   * @returns {{[p: string]: {[p: string]: *}}}
   */
  toPayload(organization: OrganizationType): any {
    return super.toPayload(organization, {
      ...ReferencesTransform.toPayload(organization, 'organization_type'),
      ...Participations.toPayload(organization, 'person_participants')
    });
  }
}

const OrganizationTransform: Organization = new Organization();
export default OrganizationTransform;
