// @flow

import cx from 'classnames';
import React, { useContext, type ComponentType } from 'react';
import { useTranslation } from 'react-i18next';
import { Header } from 'semantic-ui-react';
import styles from './UserLink.module.css';
import UserContext from '../contexts/User';

const UserLink: ComponentType<any> = () => {
  const { name } = useContext(UserContext);
  const { t } = useTranslation();

  return (
    <Header
      className={cx(styles.userLink, styles.ui, styles.header)}
      content={t('UserLink.labels.welcome', { name })}
      icon='user circle'
      size='small'
    />
  );
};

export default UserLink;
