import _ from 'underscore';
import i18n from '../i18n/i18n';
import { Visibility } from '../constants/Visibility';

const ERRORS_IMAGE_VISIBILITY = 'image_visibility';

const validate = (project) => {
  const errors = {};

  // Only "Public" images may be added to the website layout
  const images = _.filter(project.images, (image) => image.media_content.visibility !== Visibility.public);
  if (!_.isEmpty(images)) {
    _.extend(errors, { [ERRORS_IMAGE_VISIBILITY]: i18n.t('Images.errors.visibility') });
  }

  return errors;
};

export default {
  validate
};
